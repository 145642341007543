import React, { useState } from "react";
import moment from "moment";
//@material-ui/core
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse';
import { Divider } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import Zoom from '@material-ui/core/Zoom';
//@material-ui/icons
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplyIcon from '@material-ui/icons/Reply';

import DialogTitle from 'components/Views/QueryBox/components/Dialog/DialogTitle';
import Compose from 'components/Views/QueryBox/components/Compose/index';


// custom style
import useStyles from "./styles/messagesCardStyle";


const DeatilsCard = ({ currentQuery, isReplay }) => {
    const classes = useStyles();

    const fileDowload = (file) => {
        if (typeof window.Android !== 'undefined')  {
            window.Android.downloadFile( file.fileUrl);
        } else {
            fetch(file.fileUrl)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    let objectURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = objectURL;
                    document.body.appendChild(link);
                    link.setAttribute('download', file.fileName);
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    // Remove link from body
                    document.body.removeChild(link);
                });
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: "column", marginBottom: 10 }}>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <div style={{ width: '100%' }}>
                    <Typography variant="caption" style={{ fontWeight: '500' }}  >
                        {moment(currentQuery.createdOn).format('DD/MM/YYYY')}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: "row", alignItems: 'baseline' }}>
                        {/* <Avatar className={classes.small}>H</Avatar> */}
                        <Typography variant="caption" className={classes.senderName} gutterBottom>
                            {currentQuery.createdByName}
                        </Typography>
                    </div>
                </div>
            </div>

            <Typography variant="subtitle2" className={classes.messageText} >
                <div
                    dangerouslySetInnerHTML={{
                        __html: currentQuery.messageBody
                    }}
                ></div>
            </Typography>

            {currentQuery.attachmentList
                ? currentQuery.attachmentList.map((data) => {
                    return (
                        <div key={data.id}>
                            <Chip
                                deleteIcon={<GetAppIcon></GetAppIcon>}
                                label={data.fileName}
                                // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                className={classes.chip}
                                onDelete={() => fileDowload(data)}
                                onClick={() => fileDowload(data)}
                            />
                        </div>
                    );
                })
                : null
            }
        </div>
    )
}

const MessageDeatilsCard = (props) => {
    const { onReply, currentQuery } = props
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));


    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = useState(false);


    const handleChange = () => {
        setChecked((prev) => !prev);
    };



    return (<React.Fragment>


        <DialogTitle onClose={props.onClose} >
            {currentQuery.subject}
        </DialogTitle>

        <DialogContent  >

            <DeatilsCard currentQuery={currentQuery} isReplay={false} />

            {currentQuery.chatList && currentQuery.chatList.map(data => {
                return (
                    <DeatilsCard key={data.messageId} currentQuery={data} isReplay={true} />
                )
            })}


            {!checked && <DialogActions>
                <Button color="primary" startIcon={<ReplyIcon />} size='small' autoFocus variant='outlined' onClick={handleChange} >
                    Reply
                </Button>
            </DialogActions>}

        </DialogContent>


        {!sm
            ? <Collapse in={checked}>
                <Divider />
                <Paper elevation={3} style={{ marginTop: 10 }} >
                    <Compose reply replyTo={currentQuery} onClose={handleChange}/>
                </Paper>
            </Collapse>
            : <Dialog
                open={checked}
                maxWidth={"lg"}
                fullWidth={true}
                TransitionComponent={Zoom}
                fullScreen={sm}>
                <Compose onClose={handleChange} reply replyTo={currentQuery} />
            </Dialog>
        }

    </React.Fragment>)
}

export default MessageDeatilsCard;