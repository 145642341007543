import React, { useState } from 'react';
import PropTypes from "prop-types";
// import axios
import axiosConfig from "util/axios";
//Redux
import { useDispatch } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
//@material-ui/icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//import Custom Components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Dialog from '@material-ui/core/Dialog';
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
// custom style
import useStyles from "assets/jss/components/views/signupStyle";
//validations
import { checkNull, checkEmail, checkMinLength, checkMaxLength, checkMobileNumber, checkMobileNumberVerification } from 'util/validations'
//variables error
import { MIN_LENGTH_ERROR, LENGTH_ERROR } from 'variables/general'
//Common functions
import { clevertapEvents } from "util/commonfunctions";
// Views
import VerifyMobileOTP from 'components/Views/SignUp/VerifyMobileOTP';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const GreenCheckIcon = withStyles({
    root: { color: green[400] }
})((props) => <CheckCircleIcon {...props} fontSize='small' />);

const EasySignup = (props) => {
    const {
        onSignUp,
        onLogin,
        isLoginAfterSignUp,
        ...rest
    } = props;

    const dispatch = useDispatch()
    const classes = useStyles();
    const [openProgress, setOpenProgress] = useState(false)

    const [isMobileValidate, setMobileValidation] = useState(false)
    const [alertType, setAlertType] = React.useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [OTPDialog, setOTPDialog] = React.useState(false);
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        contactNumber: "",
        email: "",
        password: "",
        errorfirstName: "",
        errorlastName: "",
        errorcontactNumber: "",
        erroremail: "",
        errorpassword: ""
    })
    const [showPassword, setShowPassword] = React.useState(false);

    const handleSignup = async () => {
        let validationObject = {};
        checkNull("firstName", state.firstName, "First name is required", validationObject);
        checkNull("lastName", state.lastName, "Last name is required", validationObject);
        //checkNull("email", state.email, "Email ID is required", validationObject);
        checkNull("password", state.password, "Password is required", validationObject);
        checkEmail("email", state.email, "Email ID is not valid", validationObject);
        checkNull("contactNumber", state.contactNumber, "Mobile number is required", validationObject);

        checkMobileNumber("contactNumber", state.contactNumber, "Mobile number is not valid", validationObject);
        checkMobileNumberVerification("contactNumber", state.contactNumber, isMobileValidate, "Please verify mobile number", validationObject);
        checkMaxLength("email", state.email, 50, LENGTH_ERROR(50), validationObject);
        checkMaxLength("password", state.password, 50, LENGTH_ERROR(50), validationObject);
        checkMinLength("password", state.password, 3, MIN_LENGTH_ERROR("Password", 3), validationObject);
        checkMaxLength("firstName", state.firstName, 50, LENGTH_ERROR(50), validationObject);
        checkMaxLength("lastName", state.lastName, 50, LENGTH_ERROR(50), validationObject);

        if (Object.keys(validationObject).length != 0) {
            setState({
                ...state,
                errorfirstName: "",
                errorlastName: "",
                errorcontactNumber: "",
                erroremail: "",
                errorpassword: "",
                ...validationObject
            });
        } else {
            setOpenProgress(true);
            const userDetails = {
                "userName": state.contactNumber,
                "password": state.password,
                "firstName": state.firstName,
                "lastName": state.lastName,
                "emailId": state.email,
                "contactNo": state.contactNumber,
                "schoolName": "",
                "cityName": ""
            }

            //clevertap
            const eventData = JSON.stringify({
                'Mobilenumber': "+91"+state.contactNumber,
                'EmailId': state.email,
                'UserName': state.contactNumber
            });

            clevertapEvents('Registration', eventData);

            axiosConfig().post('/QuestUser/RegisterUser', userDetails).then(async response => {
                if (response.data.error) {
                    setOpenProgress(false);
                    setAlertType("error")
                    setSnackbarMessage(response.data.error)
                } else {
                    if (isLoginAfterSignUp) {
                        await dispatch(questActions.authenticateUser(state.contactNumber, state.password)).catch(function (error) {
                            setAlertType("error")
                            setSnackbarMessage(error.message)
                        });
                    }
                    setAlertType("success")
                    setSnackbarMessage("Registration completed successfully!")
                    setOpenProgress(false);
                    onSignUp()
                }
            }).catch(function (error) {
                console.log(error);
            });

        }
    }



    const onSendOTP = async () => {
        let validationObject = {};
        checkNull("contactNumber", state.contactNumber, "Mobile number is required", validationObject);
        checkMobileNumber("contactNumber", state.contactNumber, "Mobile number is not valid", validationObject);
        if (Object.keys(validationObject).length != 0) {
            setState({
                ...state,
                errorcontactNumber: "",
                ...validationObject
            });
        } else {
            setState({ ...state, errorcontactNumber: "" });
            setOpenProgress(true)
            await dispatch(questActions.setContactNumber(state.contactNumber));

            //clevertap
            clevertapEvents('OtpRequest', "{\"Mobilenumber\":\"+91" + state.contactNumber + "\"}");

            dispatch(questActions.requestForOTP(state.contactNumber)).then(() => {
                setOTPDialog(true)
                setOpenProgress(false)
            }).catch(error => {
                setOpenProgress(false)
                setAlertType("error")
                setSnackbarMessage(error.message)
            });
        }
    }

    const verifyMobile = (isVerify) => {
        setMobileValidation(isVerify)
        setAlertType("success")
        setSnackbarMessage("Your mobile number is verified")
    }

    const onChangeMobileNumber = (e) => {
        e.preventDefault();
        setState({ ...state, contactNumber: e.target.value })
        setMobileValidation(false)
    }

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <React.Fragment>
            {openProgress
                ? < LinearProgress color="primary" style={{ position: 'absolute', width: '95%', left: 0 }} />
                : null
            }

            <div className={classes.paper}>
                <div className={classes.form_easysignup}>
                    {/*-----Dialog----- */}
                    <Dialog open={OTPDialog} maxWidth={"sm"} TransitionComponent={Transition} >
                        <VerifyMobileOTP
                            mobileNumber={state.contactNumber}
                            onClose={setOTPDialog}
                            onValidate={verifyMobile}
                        />
                    </Dialog>

                    {/* -----Snackbar---- */}
                    <Snackbar open={snackbarMessage ? true : false} autoHideDuration={4000} onClose={() => setSnackbarMessage("")} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={() => setSnackbarMessage("")} severity={alertType}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextInput
                                id="FIRST_NAME"
                                autoFocus={true}
                                label="First Name"
                                value={state.firstName}
                                errorText={state.errorfirstName}
                                onChange={(e) => setState({ ...state, firstName: e.target.value })}
                                disabled={openProgress ? true : false}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <TextInput
                                id="LAST_NAME"
                                label="Last Name"
                                value={state.lastName}
                                errorText={state.errorlastName}
                                onChange={(e) => setState({ ...state, lastName: e.target.value })}
                                disabled={openProgress ? true : false}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <TextInput
                                type="number"
                                label="Mobile Number (Your User Name)"
                                value={state.contactNumber}
                                errorText={state.errorcontactNumber}
                                onChange={onChangeMobileNumber}
                                disabled={openProgress ? true : false}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    endAdornment: <InputAdornment position="end">
                                        {
                                            isMobileValidate
                                                ? <GreenCheckIcon />
                                                : <Chip size="small" label="Get OTP" variant="outlined" className={classes.chip_getotp} color="secondary" onClick={onSendOTP} />
                                        }
                                    </InputAdornment>
                                }}
                                onInput={(e) => {
                                    e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : ""
                                }}

                            />
                        </GridItem>


                        <GridItem xs={12} sm={12} md={12}>
                            <TextInput
                                type={showPassword ? 'text' : 'password'}
                                label="Set Password"
                                value={state.password}
                                errorText={state.errorpassword}
                                onChange={(e) => setState({ ...state, password: e.target.value })}
                                disabled={openProgress ? true : false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <TextInput
                                label="Email ID"
                                value={state.email}
                                errorText={state.erroremail}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                disabled={openProgress ? true : false}
                            />
                        </GridItem>


                    </GridContainer>


                    <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem_easysignup}>
                        <Button
                            disabled={openProgress ? true : false}
                            round
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit_easysignup}
                            onClick={handleSignup}
                        >
                            Sign Up
                        </Button>
                    </GridItem>

                    <div style={{ marginTop: 8 }}>
                        <Divider />
                    </div>

                    <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem_easysignup}>
                        <Button
                            disabled={openProgress ? true : false}
                            round
                            fullWidth
                            variant="contained"
                            color="info"
                            className={classes.submit_easysignup}
                            onClick={onLogin}
                        >
                            Already have an account? Log In
                    </Button>
                    </GridItem>

                </div>
            </div>
        </React.Fragment>
    )
}

EasySignup.defaultProps = {
    onSignUp: () => { },
    onLogin: () => { },
    isLoginAfterSignUp: false,
}

EasySignup.propTypes = {
    onSignUp: PropTypes.func,
    onLogin: PropTypes.func,
    isLoginAfterSignUp: PropTypes.bool,
}

export default EasySignup;