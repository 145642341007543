
import React from 'react';

//Custom Components
import SwipeableView from "components/CustomComponents/SwipeableView/SwipeableView";
import SwipeCard from "components/Views/QuestProducts/SwipeableViews/SwipeCard";

const AutoPlaySwipeableViews = (props) => {
    return (
        <SwipeableView index={props.activeStep} onChangeIndex={props.stepChange} >
            {props.topics.map((step, index) => (
                <div key={step.questId + "_" + index} style={{ width: '100%' }}>
                    <SwipeCard
                        name={step.questName}
                        image={step.questIcon}
                        data={step}
                        {...props}
                    />
                </div >
            ))
            }
        </SwipeableView >
    )
};

export default AutoPlaySwipeableViews;