import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { hot } from 'react-hot-loader'
import screenfull from 'screenfull'
import { withStyles } from '@material-ui/styles'
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
// import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
// import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay10';
import ForwardIcon from '@material-ui/icons/Forward10';

//Redux
import { connect } from 'react-redux'

//import reducer 
import * as questActions from 'store/actions/index'

// import './reset.css'
import './style/defaults.css'
import './style/range.css'
import './style/ckin.css'
import style from './style/style'

//config
import { serverURL, } from 'variables/ServerURL'
//axios
import { Token } from "util/commonfunctions";



import ReactPlayer from 'react-player'
import Duration from './Duration'

import { CURRENT_DATE_TIME } from "variables/general.js";
import axiosConfig from 'util/axios'



class VideoPalyer extends Component {
    constructor(props) {
        super(props);
        this.mousetrailRef = React.createRef();
        this.mouseConlRef = React.createRef();
    }
    state = {
        pip: false,
        counter: 3,
        playing: false,
        controls: false,
        light: false,
        volume: 1,
        muted: false,
        played: 0,
        loaded: 0,
        ended: false,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        auth: true,
        tokan: Token(),
        Landscape: false,
        loading: true,
        markers: []
    }


    componentDidUpdate(nextProps) {
        const { content } = this.props
        if (nextProps.content.fileUrl !== content.fileUrl) {
            this.setState({ loading: true,vtoken:null })
            if (nextProps.content.accessDuration > 1 && this.state.ended === false) {
                this.onPostContentAccess(nextProps.content)
            }
            if (this.props.isWeb) {
                //this.mousetrailRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            else
                window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }


    componentDidMount() {
        this.timerID = setInterval(this.tick, 1000);

    }


    tick = () => {
        if (this.state.counter == 0)
            clearInterval(this.timerID);
        else
            this.setState({ counter: this.state.counter - 1 });
    };

    componentWillUnmount() {
        clearInterval(this.timerID);
        const accessTime = this.state.duration * this.state.played
        if (accessTime > 1) {
            this.onPostContentAccess(this.props.content)
        }
    }


    onPostContentAccess = (content) => {
        const BODY_DATA = {
            "missionActivityId": "", // Don't set this
            "userId": this.props.userInfo.userId,
            "locationId": this.props.userInfo.locationId,
            "groupId": this.props.userInfo.groupId,
            "questId": this.props.quest.questId,
            "contentId": content.contentId,
            "contentTypeCode": content.contentTypeCode,
            "accessDate": "" + this.props.accessDateTime,
            "accessDuration": "" + content.accessDuration,
            "missionActivitySummaryId": "" // Don't set this
        }
        this.props.onPostContentAccess(BODY_DATA, content)
    }

    handleReady = () => {
        this.setState({ loading: false, playing: true, ended: false })
        this.props.onPlay(CURRENT_DATE_TIME())
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
        clearInterval(this.timerID);
        this.setState({ counter: 3 });
        this.timerID = setInterval(this.tick, 1000);
    }

    handleShowHide = async () => {
        clearInterval(this.timerID);
        this.timerID = setInterval(this.tick, 1000);
        if (this.state.counter === 0)
            this.setState({ counter: 3 });
        else
            this.setState({ counter: 0 });
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }



    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted, volume: this.state.muted ? 0.2 : 0 })
    }

    handleSetPlaybackRate = (value) => {
        this.setState({ playbackRate: parseFloat(value) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        this.setState({ pip: false })
    }

    handlePause = () => {
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {

        // Update Content
        this.props.updateContentAccessDuration(this.state.duration * state.played)

        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        this.setState({ playing: this.state.loop, ended: true })
        this.props.onEnded()
        this.onPostContentAccess(this.props.content)
    }

    handleDuration = (duration) => {
        let markers = this.state.markers.map(value => {
            let new_value = { ...value }
            // Is position within range of the duration?
            if (new_value.time <= duration) {
                // Calculate position in percentage and add to the #seekbar.
                let left = (new_value.time / duration) * 100 + '%';
                // Create marker and give it the left value.
                new_value.position = left

            }
            return new_value;
        })
        this.setState({ markers, duration })
    }

    handleAESencryption =async (xhr, url) => {
        if (url.includes("kid")) {
            url = `${serverURL}QuestContent/GetKey?ContentCode=${this.props.content.contentId}`
            xhr.open('GET', url, true);
        }
        if (url.includes("GetKey")) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.tokan)
        }
        if (url.includes("key_id")) {

            if (this.state.vtoken) {
                xhr.open('GET', url, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.vtoken)
            }
            else {
                this.setState({ loading: true})
                await axiosConfig().get('/QuestContent/GetMKIOToken', {
                    params: {
                        ContentId: this.props.content.contentId,
                    }
                }).then(response => {
                    this.setState({ vtoken: response.data.token, loading: false })
                }).catch(error=> {
                    this.setState({ loading: false})
                    console.log(error);
                });

            }


        }
    }

    handleClickFullscreen = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(1)
            this.props.onChangeOrientation("landscape")
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('setDeviceOrientation', 1, '');
            this.props.onChangeOrientation("landscape")
        }
        else {
            screenfull.request(findDOMNode(this.player))
        }
    }

    onBackPressed_Initiate = () => {
        window.Android.setDeviceOrientation(2)
        this.props.onChangeOrientation("portrait")
    }
    handleClickFullscreenExit = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.setDeviceOrientation(2)
            this.props.onChangeOrientation("portrait")
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('setDeviceOrientation', 2, '');
            this.props.onChangeOrientation("portrait")
        }
    }


    renderLoadButton = (url, label, auth) => {
        return (
            <button onClick={() => this.load(url, auth)} style={{ margin: 5 }}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }

    handleForward = () => {
        if ((this.state.duration * this.state.played) > (this.state.duration - 10)) {
            this.setState((state, props) => ({ played: 0.999999 }))
        } else {
            this.setState((state, props) => ({
                played: state.played + (10 / this.state.duration)
            }))
            this.player.seekTo(parseFloat(this.state.played + (10 / this.state.duration)))
        }
    }

    handleReplay = () => {
        if ((this.state.duration * this.state.played) >= 10) {
            this.setState((state, props) => ({
                played: state.played - (10 / this.state.duration)
            }))
            this.player.seekTo(parseFloat(this.state.played - (10 / this.state.duration)))
        } else {
            this.setState((state, props) => ({
                played: 0
            }))
        }
    }

    render() {
        // const {loaded,auth, tokan }= this.state
        const { playing, controls, light, volume, muted, loop, played, duration, playbackRate, pip, loading, counter } = this.state
        // const SEPARATOR = ' · '
        const { classes } = this.props

        return (
            <div className={`${this.props.isWeb ? 'app_center' : 'app'}`} ref={this.mousetrailRef} >
                <section className={this.props.orientation === "portrait" ? 'section' : 'section_landscape'} style={{ width: this.props.isWeb ? '75%' : '100%' }}>
                    <div className={this.props.orientation === "portrait" ? 'player-wrapper' : 'player-wrapper-landscape'}>
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            style={{
                                backgroundImage: `url(${loading ? this.props.content.imageUrl : ""})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                // position: 'relative'

                            }}
                            width='100%'
                            height='100%'
                            url={this.props.isVideo ? this.props.content.fileUrl : null}
                            config={{
                                file: {
                                    hlsOptions: {
                                        forceHLS: true,
                                        debug: false,
                                        xhrSetup: async (xhr, url) => await this.handleAESencryption(xhr, url)
                                    },
                                }
                            }}
                            playIcon={<button>Play</button>}
                            pip={pip}
                            playing={playing}
                            controls={controls}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onReady={this.handleReady}
                            onPlay={this.handlePlay}
                            onEnablePIP={this.handleEnablePIP}
                            onDisablePIP={this.handleDisablePIP}
                            onPause={this.handlePause}
                            onEnded={this.handleEnded}
                            onError={e => console.log('onError', e)}
                            onProgress={this.handleProgress}
                            onDuration={this.handleDuration}
                            playsinline={true}
                        />

                        <IconButton
                            className={`${counter !== 0 ? " default__button--big" : 'is-playing'} player-control-button`}
                            classes={{ root: classes.muiIconButtonRoot }}
                            onClick={this.handlePlayPause}>
                            {playing ? <PauseRoundedIcon
                                classes={{ fontSizeLarge: classes.muiSvgIconFontSizeLarge }}
                                fontSize="large" />
                                : <PlayArrowRoundedIcon
                                    classes={{ fontSizeLarge: classes.muiSvgIconFontSizeLarge }}
                                    fontSize="large"
                                />}
                        </IconButton>

                        <IconButton
                            className={`${counter !== 0 ? "is-replay-control" : 'is-playing'} player-control-button`}
                            classes={{ root: classes.muiIconButtonRoot }}
                            onClick={this.handleReplay}>
                            <ReplayIcon
                                fontSize="large"
                                classes={{ fontSizeLarge: classes.muiSvgIconFontSizeLarge }}
                            />
                        </IconButton>

                        <IconButton
                            className={`${counter !== 0 ? "is-forwar-control" : 'is-playing'} player-control-button`}
                            classes={{ root: classes.muiIconButtonRoot }}
                            onClick={this.handleForward}>
                            <ForwardIcon
                                classes={{ fontSizeLarge: classes.muiSvgIconFontSizeLarge }}
                                fontSize="large" />
                        </IconButton>

                        {loading && this.props.isVideo ? (
                            <div className="loader-div">
                                <div className="loader">Loading...</div>
                            </div>)
                            : null}

                        <marquee onClick={this.handleShowHide} onTouchEnd={this.handleTuchEnd} className="marquee-controls-down" direction="down" behavior="scroll" height="100%" scrollamount="1" scrolldelay="80">
                            <marquee behavior="scroll"><font >{this.props.userInfo.userName}</font></marquee>
                        </marquee>

                        {!loading
                            ? <div className={`${counter ? "speed-bar " : 'is-playing'}`}>
                                <IconButton
                                    classes={{ root: classes.muiIconButtonRoot }}
                                    style={{ color: playbackRate === 1 ? 'rgb(59, 89, 152)' : '#fff' }}
                                    onClick={() => this.handleSetPlaybackRate(1)}>
                                    <DirectionsWalkIcon
                                        classes={{ fontSizeSmall: classes.muiSvgIconFontSizeSmall }}
                                        fontSize="small" />
                                </IconButton>
                                <IconButton
                                    classes={{ root: classes.muiIconButtonRoot }}
                                    style={{ color: playbackRate === 1.5 ? 'rgb(59, 89, 152)' : '#fff' }}
                                    onClick={() => this.handleSetPlaybackRate(1.5)}>
                                    <DirectionsRunIcon value={1.5}
                                        classes={{ fontSizeSmall: classes.muiSvgIconFontSizeSmall }}
                                        fontSize="small" />
                                </IconButton>
                                <IconButton
                                    classes={{ root: classes.muiIconButtonRoot }}
                                    style={{ color: playbackRate === 2 ? 'rgb(59, 89, 152)' : '#fff' }}
                                    onClick={() => this.handleSetPlaybackRate(2)}>
                                    <DirectionsBikeIcon
                                        classes={{ fontSizeSmall: classes.muiSvgIconFontSizeSmall }}
                                        value={2} fontSize="small" />
                                </IconButton>
                            </div>
                            : null
                        }


                        <div className="controls" ref={this.mouseConlRef}>
                            <div className="load-bar">
                                <input
                                    type='range' min={0} max={0.999999} step='any'
                                    value={played}
                                    className="slider"
                                    onMouseDown={this.handleSeekMouseDown}
                                    onChange={this.handleSeekChange}
                                    onMouseUp={this.handleSeekMouseUp}
                                    onTouchStart={this.handleSeekMouseDown}
                                    onTouchEnd={this.handleSeekMouseUp}
                                />
                                <progress className="load-progress" max={1} value={played} />

                                <div className="bookmark-bar">
                                    {this.state.markers.map((val, index) => {
                                        return (
                                            <div key={"vdi#" + index} style={{ left: val.position, position: 'absolute' }}>
                                                <button className="tooltip" data-title={val.text}></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className={`${counter !== 0 ? "button-controls" : 'is-playing-control'}`}>
                                <div className="play-button">
                                    <IconButton
                                        classes={{ root: classes.muiIconButtonRoot }}
                                        onClick={this.handlePlayPause}>
                                        {playing ? <PauseRoundedIcon classes={{ root: classes.muiSvgIconFontSizeLarge }} />
                                            : <PlayArrowRoundedIcon classes={{ root: classes.muiSvgIconFontSizeLarge }} />}
                                    </IconButton>
                                </div>

                                <div className="duration-info">
                                    <div><Duration seconds={duration * played} /> /  <Duration seconds={duration} ></Duration> </div>
                                </div>

                                <div className="fullscreen-button">
                                    {
                                        typeof onRemenberMeClicked === 'undefined' ?
                                            this.props.orientation === "portrait"
                                                ? <IconButton
                                                    classes={{ root: classes.muiIconButtonRoot }}
                                                    onClick={this.handleClickFullscreen}>
                                                    <FullscreenIcon classes={{ root: classes.muiSvgIconFontSizeLarge }} />
                                                </IconButton>
                                                : <IconButton
                                                    classes={{ root: classes.muiIconButtonRoot }}
                                                    onClick={this.handleClickFullscreenExit}>
                                                    <FullscreenExit classes={{ root: classes.muiSvgIconFontSizeLarge }} />
                                                </IconButton>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div >
        )
    }
}



const mapStateToProps = state => {
    return {
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateContentAccessDuration: (accessDuration) => dispatch(questActions.updateContentAccessDuration(accessDuration)),
        onPostContentAccess: (accessData, content) => dispatch(questActions.postContentAccess(accessData, content)),//also update content,current_cuntent and quest_progress
    }

}

export default withStyles(style)(hot(module)(connect(mapStateToProps, mapDispatchToProps)(VideoPalyer)));