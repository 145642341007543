import * as actionTypes from '../actionTypes'

const initialState = {
    todyasOnlineClasses: {
        scheduleDataResult: []
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TODAYS_ONLINE_CLASSES:
            return {
                ...state,
                todyasOnlineClasses: action.schedule
            }
        default:
            return state;
    }

}


export default reducer