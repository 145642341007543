import React from "react";
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';



// custom style
import styles from "assets/jss/components/customcomponents/customInputStyle.js";

const useStyles = makeStyles(styles);

const TextInput = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    label,
    id,
    errorText,
    success,
    ...rest
  } = props;

  return (
    <FormControl fullWidth={true} error className={classes.formControl} >
      <TextField
        ref={ref}
        className={classes.marginDense}
        label={label}
        // InputLabelProps={{ shrink: true, className: classes.labelRoot }}
        InputLabelProps={{ className: classes.labelRoot }}
        margin="dense"
        size={'small'}
        // error={errorText ? true : false}
        helperText={errorText}
        FormHelperTextProps={{ className: classes.labelRootError }}
        {...rest}
      />
    </FormControl>

  );
});

TextInput.propTypes = {
  label: PropTypes.node,
  id: PropTypes.string,
  errorText: PropTypes.string,
  success: PropTypes.bool,
};

export { TextInput };

// ...........................Radio.........................
export function RadioInput(props) {
  const classes = useStyles();
  const {
    ...rest
  } = props;

  return (
    <div>
      <FormControlLabel
        {...rest}
        className={classes.labelRoot}
        classes={{ label: classes.radioRoot }}
        control={<Radio color="secondary" size="small" />}
      />
      {props.children}
    </div>
  );
}



// ...........................Checkbox.........................
export function CheckboxInput(props) {
  const classes = useStyles();
  const {
    description,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <FormControlLabel
        className={classes.labelRoot}
        classes={{ label: classes.radioRoot }}
        control={<Checkbox color="secondary" size="small" />}
        {...rest}
      />
      {description ? <FormHelperText style={{ marginLeft: 29, marginTop: -11 }}>{description}</FormHelperText> : null}
    </React.Fragment>
  );
}

Checkbox.propTypes = {
  description: PropTypes.string
};

