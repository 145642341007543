import React from "react";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles({ paper: { borderRadius: 10 } });


export default function FormDialog(props) {
    const classes = useStyles();
    const {
        children,
        ...rest
    } = props;

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            scroll="body"
            keepMounted
            classes={{ paper: classes.paper }}
            //PaperComponent={CardBody}
            {...rest}
        >
            {/* <DialogContent> */}
            {children}
            {/* </DialogContent> */}
        </Dialog>
    );
}

Dialog.propTypes = {

};
