import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  overlap: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow:
      "0px 2px 4px #0000001a, 0px 7px 7px #00000017, 0px 15px 9px #0000000d, 0px 26px 10px #00000003,0px 41px 11px transparent",

    margin: "1rem 0",
    padding: "1rem 2rem",
  },
  topContainer: {
    justifyContent: "space-between",

    alignItems: "center",
    padding: 0,
    width: "100%",
  },
  topicsTitle: {
    width: "85%",
    fontWeight: "bold",
  },
  topicsDescription: {
    margin: 0,
  },
  gridItemMissions: {
    // padding: "10px 10px",
  },
  container: {
    marginTop: "2rem",
    overflow: "hidden auto",
  },
}));

export default useStyles;
