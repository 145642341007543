import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    userquest: {
        "questList": [
            // {
            //     "userQuestId": 0,
            //     "questId": "",
            //     "questName": "",
            //     "isFree": false,
            //     "startDate": "",
            //     "endDate": "",
            //     "isDeleted": false
            // }
        ]
    },
    current_quest: {},
    error: false,
    loading: true,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERQUEST_LIST_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_USERQUEST_LIST:
            return {
                ...state,
                userquest: action.userquest,
                // current_quest: action.userquest.questList[0],
                error: false,
                loading: false
            }
        case actionTypes.FETCH_USERQUEST_LIST_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }
        case actionTypes.SET_CURRENT_QUEST:
            return {
                ...state,
                error: false,
                loading: false,
                current_quest: action.currentQuest
            }

        case actionTypes.SET_CURRENT_QUEST_BY_ID:
            return {
                ...state,
                error: false,
                loading: false,
                current_quest: state.userquest.questList.filter(op => op.questId === action.questID).reduce((acc, cv) => { return acc = cv }, {})
            }



        default:
            return state;
    }

}


export default reducer