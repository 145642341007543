import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
//@material-ui/core
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
//@material-ui/icons
import AttachFile from '@material-ui/icons/AttachFile';
import TelegramIcon from '@material-ui/icons/Telegram';

//Custom Components
import DialogTitle from 'components/Views/QueryBox/components/Dialog/DialogTitle';
import CustomInput from "components/Views/QueryBox/components/CustomInput"

//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as messageActions from "components/Views/QueryBox/store/actions/index"

//validations
import { checkNull } from "util/validations";

//Style
import useStyles from './styles.js'


const Compose = (props) => {
    const { reply, replyTo, onClose } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const contentLoading = useSelector(state => state.content.loading)
    const questContent = useSelector(state => state.content.contentlist)
    const quest = useSelector(state => state.quest.current_quest)


    const [progress, setProgress] = useState(false);

    const [subject, setSubject] = useState("");

    const [attachmentFiles, setAttachmentFiles] = React.useState([]);

    const [selectedCourse, setSelectedCourse] = useState(null)

    const [operations, setOperations] = useState([])
    const [selectedOperation, setSelectedOperation] = useState(null)

    const [queryMessage, setQueryMessage] = useState("")
    const [snackbarMessage, setSnackbarMessage] = useState("");

    //handle File Upload
    useEffect(() => {
        window.addEventListener("upload", handleFileUpload);
        return () => window.removeEventListener("upload", handleFileUpload);
    }, [])

    const handleFileUpload = (event) => {
        const data = event.detail
        const byteCharacters = atob(data.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);

        const file = new File([blob], data.filename)
        setAttachmentFiles([...attachmentFiles, file])
    }


    // Handle Delete
    const handleDelete = (fileToDelete) => () => {
        setAttachmentFiles(attachmentFiles.filter((file,index) => index !== fileToDelete));
    };

    // On file select (from the pop up) 
    const onAttachment = event => {
        if (event.target.files[0]) {
            const upload_file = event.target.files[0];
            setAttachmentFiles([...attachmentFiles, upload_file])
        }
    };

    const onAttachmentMobile = () => {
        if (typeof window.Android !== 'undefined') {
            window.Android.uploadFile('QueryBox', reply ? replyTo.messageId : 0);
        }
    }


    const onChnageCourse = (event, newValue) => {
        setSelectedCourse(newValue)
        if (newValue)
            setOperations(questContent.add.operations.filter(data => data.missionId === newValue.missionId))
        else
            setOperations([])
        setSelectedOperation(null)
    }


    const onChnageOperations = (event, newValue) => {
        setSelectedOperation(newValue)
    }

    // Post Query Message
    const onPostQueryMessage = async () => {
        const MessageBody = {
            "subject": reply ? replyTo.subject : subject,
            "messageBody": queryMessage,
            "questId": quest.questId,
            "missionId": reply ? replyTo.missionId : selectedCourse ? selectedCourse.missionId : '',
            "operationId": reply ? replyTo.operationId : selectedOperation ? selectedOperation.operationId : '',
            "parentMessageId": reply ? replyTo.messageId : 0
        }

        if (MessageBody.subject === null || MessageBody.subject === '')
            setSnackbarMessage("Please specify subject.")
        else if (MessageBody.missionId === null || MessageBody.missionId === '')
            setSnackbarMessage("Please specify at least one Mission. ")
        else {
            setProgress(true)
            await dispatch(messageActions.postQueryMessage(MessageBody, attachmentFiles)).then(() => {
                setProgress(false)
                onClose()
            }).catch(error => {
                setProgress(false)
                setSnackbarMessage(error.message)
            })
        }

    }

    /*handle Close Snackbar*/
    const handleClose = () => {
        setSnackbarMessage("");
    }

    return (
        <React.Fragment>

            {/* ------Snackbar------ */}
            <Snackbar
                open={snackbarMessage ? true : false}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={'error'} elevation={6} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {!reply
                ? <DialogTitle onClose={onClose}  >
                    Compose
                </DialogTitle>
                : sm && <DialogTitle onClose={onClose}  >
                    {subject}
                </DialogTitle>
            }

            <DialogContent  >

                {!reply && <CustomInput
                    formControlClasses={classes.formControl}
                    labelText="Query Subject"
                    disabled={progress ? true : false}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />}

                {!reply && <Autocomplete
                    disabled={progress ? true : false}
                    options={questContent.add.missions}
                    value={selectedCourse}
                    onChange={onChnageCourse}
                    getOptionLabel={(option) => option.missionDisplayName ? option.missionDisplayName : ""}
                    renderInput={(params) => <TextField {...params} label="Select Mission" margin='dense' variant="outlined" />}
                />
                }

                {!reply && <Autocomplete
                    disabled={progress ? true : false}
                    options={operations}
                    value={selectedOperation}
                    onChange={onChnageOperations}
                    getOptionLabel={(option) => option.operationDisplayName ? option.operationDisplayName : ""}
                    renderInput={(params) => <TextField {...params} label="Select Operation" margin='dense' variant="outlined" />}
                />
                }

                <CustomInput
                    disabled={progress ? true : false}
                    underline={false}
                    placeholder="Compose Message"
                    multiline
                    inputProps={{ min: 5, max: 10 }}
                    value={queryMessage}
                    onChange={event => setQueryMessage(event.target.value)}
                />

                {attachmentFiles.map((file,index) => {
                    return (
                        <div key={file.lastModified}>
                            <Chip
                                label={file.name}
                                onDelete={handleDelete(index)}
                                className={classes.chip}
                            />
                        </div>
                    );
                })}

            </DialogContent >

            <DialogActions>
                {typeof window.Android !== 'undefined'
                    ? <IconButton component="span" onClick={onAttachmentMobile}>
                        <AttachFile fontSize='small' />
                    </IconButton>
                    : <div>
                        <input accept="*" className={classes.input} id="icon-button-file" type="file" onChange={onAttachment} />
                        <label htmlFor="icon-button-file">
                            <IconButton component="span">
                                <AttachFile fontSize='small' />
                            </IconButton>
                        </label>
                    </div>
                }
                {/* <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    startIcon={<SaveIcon />}
                >
                    Save
                </Button> */}
                {/* <IconButton className={classes.saveButton} >
                    <SaveIcon />
                </IconButton> */}
                <Button
                    size="small"
                    variant="outlined"
                    color='secondary'
                    startIcon={<TelegramIcon />}
                    onClick={() => onPostQueryMessage()}
                >
                    Send
                </Button>

                {/* <IconButton className={classes.saveButton} >
                    <TelegramIcon />
                </IconButton> */}
            </DialogActions>

            {progress ? <LinearProgress color="secondary" /> : null}

        </React.Fragment >
    )
}

Compose.defaultProps = {
    replyTo: {},
    onClose: () => { }
}

Compose.propTypes = {
    reply: PropTypes.bool,
    replyTo: PropTypes.string,
    onClose: PropTypes.func
};

export default Compose;