import {
    dangerColor,
    boxShadow
  } from "assets/jss/material-dashboard-react.js";

  import { makeStyles } from "@material-ui/core/styles";
  //.....styles..........

  const useStyles = makeStyles((theme) => ({
    cardStyle: {
      marginBottom: "30px",
      marginTop: "30px",
      borderRadius: 10,
      ...boxShadow
  },
  listGridContainer: {
    display: 'flex',
    justifyContent: "center"
  },
    
  }));
  
  export default useStyles;