import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    questcategories: {
        lastViewed: {
            questId: "",
            questName: "",
            viewedOn: ""
        },
        questCategory: []
    },
    questdetails: {}

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_QUESTCATEGORY:
            return {
                ...state,
                questcategories: {...state.questcategories,...action.questCategory}
            }
        case actionTypes.SET_QUEST_DETAILS:
            return {
                ...state,
                questdetails: action.questDetails
            }


        default:
            return state;
    }

}


export default reducer