import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    shareddata: {
        friendsList: [{userId: "", name: "", isShared: false}],
        sharedList: [],
    },
    fetch_error: false,
    fetch_loading: false,

    post_error: false,
    post_loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        // ------- Fetch Shared Data -------------
        case actionTypes.FETCH_SHARED_DATA_START:
            return {
                ...state,
                fetch_loading: true
            }
        case actionTypes.SET_SHARED_DATA:
            return {
                ...state,
                shareddata: action.sharedData,
                fetch_error: false,
                fetch_loading: false
            }
        case actionTypes.FETCH_SHARED_DATA_FAILED:
            return {
                ...state,
                fetch_error: true,
                fetch_loading: false
            }

        // ------- Post Shared Data -------------
        case actionTypes.POST_SHARED_DATA_START:
            return {
                ...state,
                post_error: false,
                post_loading: true
            }

        case actionTypes.POST_SHARED_DATA_SUCCESS:
            return {
                ...state,
                post_error: false,
                post_loading: false
            }

        case actionTypes.POST_SHARED_DATA_FAILED:
            return {
                ...state,
                post_error: true,
                post_loading: false
            }
        default:
            return state;
    }

}


export default reducer