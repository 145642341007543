import GridContainer from "components/CustomComponents/Grid/GridContainer.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Card from "components/CustomComponents/Card/Card.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";
import CardIcon from "components/CustomComponents/Card/CardIcon.js";
import CardFooter from "components/CustomComponents/Card/CardFooter.js";
import Info from "components/CustomComponents/Typography/Info";
import CardActionArea from "@material-ui/core/CardActionArea";
import React, { Suspense, lazy, useState, useEffect } from "react";

import PageNavigation from "components/CustomComponents/Navbars/PageNavigation";
// import TestImage from "assets/img/test.svg";
// import ReportImage from "assets/img/report.svg";
import ReportCard from "components/CustomComponents/Card/ReportCard";
import TestImage from "assets/img/Review_Assissment.png";
import ReportsImage from "assets/img/school_rep.png";
import useStyles from "assets/jss/components/customcomponents/mainReport.js";
function AssementDashBoard(props) {
  const classes = useStyles();
  const basePath = props.match.path;
  const handleClick = (path) => {
    props.history.push(path);
  };
  return (
    <>
      <PageNavigation logoText={"Home"} back={"/home"} />
      <GridContainer className={classes.container}>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          className={classes.gridItemMissions}
        >
          <ReportCard
            icon={TestImage}
            iconStyle="icon_Question"
            title={"Test"}
            subTitle="Access all your assigned assessments here and submit."
            backgroundColor={"#E5A79C"}
            onClick={() => handleClick("/assessment-tests")}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={4}
          className={classes.gridItemMissions}
        >
          <ReportCard
            icon={ReportsImage}
            iconStyle="icon_diy"
            title={"Reports"}
            backgroundColor={"#EA7980"}
            subTitle="Check comprehensive reports on students' completed tests."
            onClick={() => handleClick("/assessment-report")}
          />
        </GridItem>
      </GridContainer>
      {/* <GridContainer>
        <GridItem xs={12} sm={6} md={2} className={classes.gridItemMissions}>
          <Card
            className={classes.cardMissions}
            onClick={() => props.history.push("/assessment-tests")}
          >
            <CardActionArea>
              <CardBody plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardIcon>
                      <img
                        className={classes.imageStyle}
                        src={TestImage}
                        alt="..."
                      />
                    </CardIcon>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.stats}>
                      <Info className={classes.infoText}>Test</Info>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </CardActionArea>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={2} className={classes.gridItemMissions}>
          <Card
            className={classes.cardMissions}
            onClick={() => props.history.push("/assessment-report")}
          >
            <CardActionArea>
              <CardBody plain>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardIcon>
                      <img
                        className={classes.imageStyle}
                        src={ReportImage}
                        alt="..."
                      />
                    </CardIcon>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.stats}>
                      <Info className={classes.infoText}>Reports</Info>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </CardActionArea>
          </Card>
        </GridItem>
      </GridContainer> */}
    </>
  );
}

export default AssementDashBoard;
