import React from 'react';
import SwipeableViews from 'react-swipeable-views';
//@material-ui/core
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const SwipeableView = (props) => {
    const { children, ...rest } = props
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    return (
        <SwipeableViews
            {...rest}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            containerStyle={{ width: sm ? '100%' : '20%', minWidth: 200 }}
            style={{ padding: props.index === 0 ? md ? '0px 59% 0px 0px' : '0px 60px 0px 0px' : md ? '0px 49.5% 0 10%' : '0px 30px' }}
            enableMouseEvents
            ignoreNativeScroll={true}
        >
            {children}
        </SwipeableViews >
    )
};

export default SwipeableView;