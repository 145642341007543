import * as actionTypes from 'store/actions/actionTypes'

const initialstate = {
    boards: [],
    grades: []
}

const reducer = (state = { ...initialstate }, action) => {
    switch (action.type) {
        case actionTypes.SET_BOARD_GRADE:
            return {
                ...state,
                boards: action.payload.boards,
                grades: action.payload.grades,
            };
        default:
            return state;
    }
};

export default reducer