import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    cartitems: [],
    PGData: {},
    paymentStatus: {},
    couponStatus: {},
    couponCode: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // ------- add to cart -------------
        case actionTypes.ADD_TO_CART:
            return {
                ...state,
                cartitems: [...state.cartitems, action.cartitem],
                couponStatus: {},
                couponCode: ""
            }
        case actionTypes.REMOVE_FROM_CART:
            let newCartItems = [...state.cartitems].filter(data => data.productId !== action.cartitem.productId)
            return {
                ...state,
                cartitems: newCartItems,
                couponStatus: {},
                couponCode: ""
            }
        case actionTypes.CLEAR_CART:
            return {
                ...state,
                cartitems: [],
                couponStatus: {},
                couponCode: ""
            }
        case actionTypes.SET_CART_ITEMS:
            return {
                ...state,
                cartitems: [...action.cartitems]
            }
        //---------- Payment Gatway ----------------
        case actionTypes.SET_PG_DATA:
            return {
                ...state,
                PGData: { ...action.PGData }
            }
        //----------set Payment Status ----------------
        case actionTypes.SET_PAYMENT_STATUS:
            return {
                ...state,
                paymentStatus: { ...action.paymentStatus }
            }
        //----------set Coupon Status ----------------
        case actionTypes.SET_STORE_COUPON_STATUS:
            return {
                ...state,
                couponStatus: { ...action.couponStatus }
            }

        //----------set Coupon code ----------------
        case actionTypes.SET_STORE_COUPON_CODE:
            return {
                ...state,
                couponCode: action.couponCode
            }


        default:
            return state;
    }

}


export default reducer