import {
    dangerColor,
    boxShadow,
    primaryColor
} from "assets/jss/material-dashboard-react.js";

import { makeStyles } from "@material-ui/core/styles";
//.....styles..........

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        //   marginBottom: "30px",
        //   marginTop: "30px",
        borderRadius: 0,
        borderBottomStyle: 'inset',
        //   ...boxShadow,
        padding: 10
    },
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },
    paperName: {
        color:primaryColor[0],
        width: '100%',
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    senderName: {
        width: '100%',
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    subject: {
        // fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '1',
        },
    },
    messageText: {
        overflow: 'hidden',
        lineHeight: 1.3,
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '2',
        },
        fontSize: '0.875rem',
        fontWeight: '500',
        letterSpacing: ' 0.00714em'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: 14,
        marginRight: 5
    },
    gridContainerStyle: {
        margin: 0,
        height: '100%',
        overflowY: 'overlay',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            //   height: '60vh',
        },
    },
    gridItemStyle: {
        padding: 0
    },
    cardContent: {
        padding: '0px 16px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 2px',
        },
    },
    chip: {
        margin: theme.spacing(0.2),
        borderRadius: 2,
        maxWidth: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '-webkit-fill-available',
        },
    },
    iconTime: {
        fontSize: 14,
        marginRight: 2
    },
    icon_text: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 15
    },
    not_attempt:{
        color:'#f44336',
    }

}));

export default useStyles;