import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    products: []

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_RAYMOND_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }

        default:
            return state;
    }

}


export default reducer