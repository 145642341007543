import React from 'react';

// @material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"

// custom style
import useStyles from "assets/jss/components/views/questCategoryStyle";

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (
        <div style={{ padding: 30 }} >
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6} >
                            <Skeleton animation="wave" variant="rect" height={40} style={{ margin: '0px 10px 10px 10px' }} />
                        </GridItem>
                    </GridContainer>


                    <Card className={classes.cardStyle} style={{ margin: 10 }}>
                        <CardContent>
                            <Typography variant="h4" component="h2">
                                <Skeleton animation="wave" />
                            </Typography>
                            <Skeleton animation="wave" variant="rect" height={40} />
                        </CardContent>
                    </Card>

                </GridItem>


                <GridItem xs={12} sm={12} md={12} style={{ margin: 10., marginTop: 30 }}>
                    <GridContainer>
                        {['001', '010', '011', '111', '112', '113'].map((v, i) =>
                            <GridItem key={"SKL" + v} xs={12} sm={3} md={2} style={{ marginBottom: 10 }}>
                                <Card className={classes.contentCardStyle} >
                                    <Skeleton variant="rect" height={220} />
                                    <CardContent >
                                        <div style={{ display: 'flex', flexDirection: "column", width: '100%' }}>
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </div>
                                    </CardContent>
                                </Card>
                            </GridItem>
                        )}
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default SkeletonCard;
