import * as actionTypes from 'store/actions/actionTypes'
import { percentageNumber } from 'util/commonfunctions';

const initialState = {
    contentlist: {
        add: {
            questContents: [],
            missions: [],
            operations: [],
            tasks: [],
            subTasks: [],
            missionTests: []
        },
    },
    current_quest_progress: {
        "questId": "",
        "progress": 0,
        "avCount": 0,
        "testCount": 0,
        "activityCount": 0,
        "webContentCount": 0
    },
    current_mission: {},
    current_operation: {},
    current_content: {},
    current_test: {},
    current_test_history: {},
    error: false,
    loading: true,

    content_ratings_loading: true,
    content_ratings: {
        "likeCount": 0,
        "disLikeCount": 0,
        "viewCount": 0,
        "myRating": null
    },
    content_ratings_error: false,
}

const getMergedArray = (contentList, progress, filterID) => {
    return contentList.map(questContents => {
        let mergedContents = { ...questContents }
        progress.forEach((currentValue) => {
            if (currentValue[filterID] === mergedContents[filterID])
                mergedContents['progress'] = { ...currentValue }
        })
        return mergedContents
    });
}

const getUpdatedProgress = (contentList, currentContent, marksObtained, filterID) => {
    return contentList.map(content => {
        let newContent = { ...content }
        let newContentProgress = { ...content.progress }
        if (content[filterID] === currentContent[filterID]) {
            newContentProgress = getTasksCounts(newContentProgress, currentContent)
            newContentProgress.points = Number(newContentProgress.points) + Number(marksObtained)
            //    ----------webContentCount-----------
            const totalTask = newContent.videoCount + newContent.testCount + newContent.activityCount + newContent.webContentCount
            const completedTask = newContentProgress.avCount + newContentProgress.testCount + newContentProgress.activityCount + newContentProgress.webContentCount
            newContentProgress.progress = percentageNumber(completedTask, totalTask)
        }
        newContent.progress = newContentProgress
        // console.log(newContent);
        return newContent
    })
}

const getTasksCounts = (newContentProgress, currentContent) => {
    if (['01', '02', '03'].indexOf(currentContent.contentTypeCode) >= 0) // is AV
    {
        newContentProgress.avCount = newContentProgress.avCount + 1
    }
    if (['08', '09'].indexOf(currentContent.contentTypeCode) >= 0) // is Test
    {
        newContentProgress.testCount = newContentProgress.testCount + 1
    }
    if (['04', '05', '06', '07'].indexOf(currentContent.contentTypeCode) >= 0) // is web content
    {
        newContentProgress.webContentCount = newContentProgress.webContentCount + 1
    }
    if (['10'].indexOf(currentContent.contentTypeCode) >= 0) // is Activity
    {
        newContentProgress.activityCount = newContentProgress.activityCount + 1
    }
    return newContentProgress
}

const updatedQuestProgress = (updatedContentList, currentQuestProgress, currentContent, marksObtained) => {

    currentQuestProgress = getTasksCounts(currentQuestProgress, currentContent)

    currentQuestProgress.points = Number(currentQuestProgress.points) + Number(marksObtained)

    const totalVideoCount = updatedContentList.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.videoCount, 0)
    const totalTestCount = updatedContentList.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.testCount, 0)
    const totalActivityCount = updatedContentList.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.activityCount, 0)
    const totalWebContentCount = updatedContentList.add.missions.reduce((accumulator, currentValue) => accumulator + currentValue.webContentCount, 0)

    const totalTask = totalVideoCount + totalTestCount + totalActivityCount + totalWebContentCount
    const completedTask = currentQuestProgress.avCount + currentQuestProgress.testCount + currentQuestProgress.activityCount + currentQuestProgress.webContentCount
    currentQuestProgress.progress = percentageNumber(completedTask, totalTask)

    // console.log("---------------------------------");
    // console.log(currentQuestProgress);
    return currentQuestProgress
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        //----- Fetch Content List------------------
        case actionTypes.FETCH_CONTENTLIST_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_CONTENTLIST:
            let newContentList = { ...action.contentlist }
            let questProgress = action.userprogress.questProgress.filter(current_progress => current_progress.questId === action.questid).reduce((acc, cv) => { return acc = cv }, {})

            newContentList.add.missions = getMergedArray(newContentList.add.missions, action.userprogress.missionProgress, 'missionId')
            newContentList.add.operations = getMergedArray(newContentList.add.operations, action.userprogress.operationProgress, 'operationId')

            newContentList.add.questContents = getMergedArray(newContentList.add.questContents, action.userprogress.contentProgress, 'contentId').map(data => {
                data.isExpanded = true;
                return data
            });

            newContentList.add.tasks = [...newContentList.add.tasks].map(data => {
                data.isExpanded = false;
                return data
            });

            return {
                ...state,
                contentlist: newContentList,
                current_quest_progress: questProgress,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_CONTENTLIST_FAILED:
            return {
                ...state,
                contentlist: initialState.contentlist,
                current_quest_progress: initialState.current_quest_progress,
                error: true,
                loading: false,
            }

        // ----------Update Content List ----------
        case actionTypes.UPDATE_CONTENTLIST:
            return {
                ...state,
                contentlist: action.contentlist,
            }

        //------------set current mission------------
        case actionTypes.SET_CURRENT_MISSION:
            return {
                ...state,
                current_mission: { ...action.mission }
            }

        //------------set current operation--------------
        case actionTypes.SET_CURRENT_OPERATION:
            let operation = { ...state.current_operation }
            if (state.contentlist.add.operations.length !== 0) {
                operation = action.operation
                    ? action.operation
                    : [...state.contentlist.add.operations].filter(op => (op.missionId === state.current_mission.missionId) && op.sequenceNo == '1').reduce((acc, cv) => { return acc = cv }, {})
            }
            return {
                ...state,
                current_operation: operation
            }

        //-----set current content-----------------
        case actionTypes.SET_CURRENT_CONTENT:
            const newCurrent_content = { ...action.content };
            return {
                ...state,
                current_content: { ...newCurrent_content, 'pointCardSatus': {}, 'accessDuration': 0 }
            }

        //-----Clear current content-----------------
        case actionTypes.CLEAR_CURRENT_CONTENT:
            const clearedCurrent_content = { ...initialState.current_content };
            return {
                ...state,
                current_content: { ...clearedCurrent_content, 'pointCardSatus': {}, 'accessDuration': 0 }
            }

        //-----update access duration content-----------------
        case actionTypes.UPDATE_ACCESS_DURATION:
            return {
                ...state,
                current_content: { ...state.current_content, 'accessDuration': action.accessDuration }
            }

        //-----UPDATE current content-----------------
        case actionTypes.UPDATE_CURRENT_CONTENT: // for marksObtained,pointCardSatus

            let updatedCurrent_content = action.content === null ? { ...state.current_content } : { ...action.content };
            let updatedContentList = { ...state.contentlist }
            let currentQuestProgress = { ...state.current_quest_progress }

            // updatedCurrent_content.pointCardSatus = action.pointCardSatus;

            //update Contents
            updatedContentList.add.questContents = updatedContentList.add.questContents.map(content => {
                let newContent = { ...content }
                let newContentProgress = { ...content.progress }
                if (content.contentId === updatedCurrent_content.contentId)
                    newContentProgress.accessCount = newContentProgress.accessCount + 1
                newContent.progress = newContentProgress
                return newContent
            })


            if (updatedCurrent_content.progress.accessCount === 0) {
                //update missions
                updatedContentList.add.missions = getUpdatedProgress(updatedContentList.add.missions, updatedCurrent_content, action.pointCardSatus.points, 'missionId')
                //update operations
                updatedContentList.add.operations = getUpdatedProgress(updatedContentList.add.operations, updatedCurrent_content, action.pointCardSatus.points, 'operationId')
                //Update Current Quest Progress
                currentQuestProgress = updatedQuestProgress(updatedContentList, currentQuestProgress, updatedCurrent_content, action.pointCardSatus.points)
            }

            return {
                ...state,
                // current_content: updatedCurrent_content,
                contentlist: updatedContentList,
                current_quest_progress: currentQuestProgress
            }


        //-----set current Test-----------------
        case actionTypes.SET_CURRENT_TESTS:
            let missionTests = { ...state.current_test }
            if (state.contentlist.add.missionTests.length !== 0)
                missionTests = [...state.contentlist.add.missionTests].filter(op => op.testId === action.testCode).reduce((acc, cv) => { return acc = cv }, {})
            missionTests.testAttemptedDuration = 0
            missionTests.testAttemptCount = 0 // this test Attempt Count for current Test is submit(1) or not(0)
            return {
                ...state,
                current_test: missionTests
            }

        //-----set current Test History -----------------
        case actionTypes.SET_CURRENT_TEST_HISTORY:
            return {
                ...state,
                current_test_history: action.data
            }


        //-----update Test Attempt Count-----------------
        // For check Current Test is attempted or not 
        case actionTypes.UPDATE_TEST_ATTEMPT_COUNT:
            let updateedMissionTests = { ...state.current_test }
            updateedMissionTests.testAttemptCount = updateedMissionTests.testAttemptCount + 1
            return {
                ...state,
                current_test: updateedMissionTests
            }


        //-----Update Test Attempt Duration-----------------
        case actionTypes.UPDATE_TEST_ATTEMPT_DURATION:
            let updatedTests = { ...state.current_test }
            updatedTests.testAttemptedDuration = updatedTests.testAttemptedDuration + action.duration
            return {
                ...state,
                current_test: updatedTests
            }

        // ------- Post Content Rating-------------
        case actionTypes.POST_CONTENT_RATING_START:
            return {
                ...state,
                content_ratings_error: false,
                content_ratings_loading: true
            }

        case actionTypes.POST_CONTENT_RATING_SUCCESS:
            return {
                ...state,
                content_ratings_error: false,
                content_ratings_loading: false
            }

        case actionTypes.POST_CONTENT_RATING_FAILED:
            return {
                ...state,
                content_ratings_error: true,
                content_ratings_loading: false
            }

        // ------- Fetch Content Rating-------------
        case actionTypes.FETCH_CONTENT_RATING_START:
            return {
                ...state,
                content_ratings_error: false,
                content_ratings_loading: true
            }
        case actionTypes.SET_CONTENT_RATING:
            return {
                ...state,
                content_ratings: action.contentRatings,
                content_ratings_error: false,
                content_ratings_loading: false
            }
        case actionTypes.FETCH_CONTENT_RATING_FAILED:
            return {
                ...state,
                content_ratings_error: true,
                content_ratings_loading: false
            }

        // ------- Post Content Access-------------
        case actionTypes.POST_CONTENT_ACCESS_START:
            return {
                ...state,
                error: false,
                loading: true
            }

        case actionTypes.POST_CONTENT_ACCESS_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false
            }

        case actionTypes.POST_CONTENT_ACCESS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }




        default:
            return state;
    }

}





export default reducer