import React from "react";
import { CardActionArea, makeStyles } from "@material-ui/core";
import styles from "assets/jss/components/customcomponents/reportCard.js";
const useStyles = makeStyles(styles);
function ReportCard(props) {
  const classes = useStyles();

  const { title, icon, backgroundColor, subTitle, ...rest } = props;
  return (
    <div className={classes.overlap_13}>
      <CardActionArea className={classes.cardBoady} {...rest}>
        <div className={classes.card_wrapper}>
          <div
            className={classes.image_new_wrapper}
            style={{ backgroundColor: backgroundColor }}
          >
            <img className={classes.image_4} alt="Image" src={icon} />
          </div>
          <div className={classes.card_content}>
            <div className={classes.card_heading_text}>{title}</div>
            <p className={classes.card_subHeading_text}>{subTitle}</p>
          </div>
        </div>
      </CardActionArea>
    </div>
  );
}

export default ReportCard;
