import {
  dangerColor,
  whiteColor,
  secondaryColor
} from "assets/jss/material-dashboard-react.js";
//.....styles..........
const signinStyle = theme => ({

  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing() * 5,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 440,
      minHeight: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textAlign: 'center',
  },
  paper: {
    textAlign: 'center',
    // boxShadow: '0px 8px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    maxWidth: '328px',
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    borderRadius: 20,
    maxWidth: '328px',
    maxHeight: '48px',
    marginTop: theme.spacing() * 1.5
  },

  submit1: {
    borderRadius: 20,
    maxWidth: '328px',
    maxHeight: '48px',
    marginTop: theme.spacing() * 1.5,

  },

  [`@media (height: 736px)`]: {
    submit1: {
      marginTop: theme.spacing() * 10,
    }
  },


  close: {
    padding: theme.spacing() / 2,
  },
  hyperText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  labelRootError: {
    color: whiteColor,//dangerColor[0],
    marginTop: 0
  },
  checkboxStyle: {
    // padding:'7px 30px'
    paddingTop: 7,
  },
  checkboxLabelStyle: {
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "capitalize",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
  },

  white: {
    color: '#ffffff',
  },

  forgotPasswordLable: {
    paddingTop: '15px',
    marginLeft: 0,
    marginRight: 0,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "capitalize",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
  },
  paperDialog: {
    borderRadius: 10
  },
  signinPaperContainer: {
    backgroundColor: secondaryColor[1],
    borderRadius: 20,
    position: "relative"
  },
  inputUnderlinewhite: {
    color: '#fff',
    "&:before": {
      borderBottom: '1px solid rgb(255 255 255)'
    },
    "&:after": {
      borderBottom: '2px solid black'
    }


  },

  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },

});

export default signinStyle;