const styles = (theme) => ({
  // ----------
  overlap_13: {
    borderRadius: "8px",
    background: "#fff",
    marginBottom: 0,
    marginTop: "12px",
    transition: "box-shadow 0.3s ease-in-out", // Transition for smooth hover effect
    "&:hover": {
      boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.1)",
      border: "#000",
    },
  },

  cardBoady: {
    flex: "1 1 auto",
    position: "relative",
    "-webkit-box-flex": 1,
  },

  image_wrapper: {
    display: "flex",
    justifyContent: "center",
    paddingRight: 0,
    paddingLeft: 30,
  },
  image_4: {
    height: "130px",
    width: "130px",
  },

  text_wrapper_56: {
    color: "#ffffff",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: 1,
    paddingRight: 15,
    "-webkit-line-clamp": "1",

    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  text_wrapper_57: {
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: 1,
    lineHeight: "20px",
    paddingRight: 15,
    overflow: "hidden",
    "-webkit-line-clamp": "2",
  },

  card_wrapper: {
    display: "flex",
    gap: "15px",
    border: "1px solid rgba(105, 105, 105, 0.33)",
    borderTopLeftRadius: "13px",
    borderBottomLeftRadius: "13px",
    borderRadius: "12px",
  },

  image_new_wrapper: {
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45%",
    padding: "15px",
  },

  card_heading_text: {
    color: "#000000",
    fontSize: "24px",
    fontWeight: 600,
  },

  card_content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    width: "100%",
    padding: "0 10px",
  },

  card_sub_heading_text: {
    color: "#696969",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21px",
  },
  card_subHeading_text: {
    color: "#777777",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
  },
});

export default styles;
