import {
  successColor,
  primaryColor,
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = theme => ({
  profilePicContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    // marginLeft:'20px',
    marginRight: '20px'
  },
  profilePicAvatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  largeAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: 5,
    // marginLeft:'20px',
    marginRight: '20px'
  },

  layout: {
    width: 'auto',
    display: 'block',
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      padding: 20,
      marginRight: 'auto',
    },
  },
  infoText: {
    color: primaryColor[4],
    [theme.breakpoints.down(600)]: {
      padding: '0.9375rem 5px'
    },
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    textAlign: 'center'
  },
  taskDiv: {
    padding: '0px 10px 0px 10px',
    borderRightStyle: 'solid',
    borderRightColor: primaryColor[0],
    flexDirection: "row", display: 'flex', justifyContent: 'center'
  },
  timeDiv: {
    padding: '0px 10px 0px 10px',
    flexDirection: "row", display: 'flex', justifyContent: 'center'
  },

  pageCardBody: {
    minHeight: '100vh',
  },
  gridItemFooter: {
    padding: '0 !important'
  },
  cardStyle: {
    marginBottom: "0",
    marginTop: '0',
    padding: 5,
    borderRadius: '20px'
    // backgroundColor:successColor[0]
  },
  successColor: {
    backgroundColor: successColor[0],
    margin: "0px",
  },
  cardBodyStyle: {
    padding: 0
  },
  cardIconStyle: {
    width: '90px',
    height: '90px',
    marginTop: '50px',
    marginBottom: '60px',
  },




  imageStyle: {
    width: "auto",
    height: "-webkit-fill-available"
  },

  earnPoints: {
    // padding: '5px 20px 5px 20px',
    width: 'fit-content',
    textAlign: 'center',
    Display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: "15px"
  },
  buttonCenter: {
    display: 'flex',
    justifyContent: "center"
  },

  nested: {
    paddingLeft: theme.spacing(8),
  },


})

export default dashboardStyle;
