import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

//-------------------------- Fetch Raymond Products -----------------------------------------
export const setRaymondProducts = (products) => {
    return {
        type: actionTypes.SET_RAYMOND_PRODUCTS,
        payload: products
    }
}

export const fetchRaymondProducts = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/Quest/GetRaymondProducts', {
            params: {
                UserId: UserId
            }
        }).then(async response => {
            await dispatch(setRaymondProducts(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}