import * as actionTypes from './actionTypes';
// import axios
import axiosConfig from "util/axios";

//import for update Current  Content,Content List &  User Progress
import { updateCurrentContent,setCurrentTestHistory } from "store/actions/contentList"

export const fetchTestQuestionsStart = () => {
    return {
        type: actionTypes.FETCH_TESTQUESTIONS_START
    }
}

export const setTestQuestions = (questions) => {
    questions["qadd"] = questions.qadd.map(qsn => {
        qsn.options.optionItems = qsn.options.optionItems.map(opt => {
            opt['ischecked'] = false
            return opt
        }).sort(() => Math.random() - 0.5)
        return qsn
    }).sort(() => Math.random() - 0.5)
    return {
        type: actionTypes.SET_TESTQUESTIONS,
        questions: questions
    }
}

export const fetchTestQuestionsFailed = () => {
    return {
        type: actionTypes.FETCH_TESTQUESTIONS_FAILED
    }
}

export const initQuestions = (testCode) => {
    return dispatch => {
        dispatch(fetchTestQuestionsStart());
        axiosConfig().get('/QuestContent/GetTestQuestion', {
            params: {
                testCode: testCode,
            }
        }).then(response => {
            dispatch(setTestQuestions(response.data))
            dispatch(initTestAnswers(response.data)) // init Answers Array For Post Assessment 
        }).catch((error) => {
            dispatch(fetchTestQuestionsFailed())
        });
    }
}

//-------- Set Test Answer------------------
export const initTestAnswers = (answers) => {
    return {
        type: actionTypes.SET_TEST_ANSWERS,
        answers: answers
    }
}

//-------- update Question Answer------------------
export const updateQuestioAnswer = (answer, questionId) => {
    return {
        type: actionTypes.UPDATE_QUESTION_ANSWER,
        answer: answer,
        questionId: questionId
    }
}

//-------- update Answer duration------------------
export const updateAnswerDuration = (duration, questionId) => {
    return {
        type: actionTypes.UPDATE_ANSWER_DURATION,
        duration: duration,
        questionId: questionId
    }
}

//----------- Post Test Attempt----------
export const postTestAttemptStart = () => {
    return {
        type: actionTypes.POST_TEST_ATTEMPT_START
    }
};

export const postTestAttemptSuccess = () => {
    return {
        type: actionTypes.POST_TEST_ATTEMPT_SUCCESS
    }
};

export const postTestAttemptFailed = () => {
    return {
        type: actionTypes.POST_TEST_ATTEMPT_FAILED
    }
};

export const postTestAttempt = (formData) => {
    return dispatch => {
        dispatch(postTestAttemptStart());
        axiosConfig().post('/QuestUser/SendTestAttempt', formData)
            .then(response => {
                dispatch(postTestAttemptSuccess())
                dispatch(updateCurrentContent(response.data))
                dispatch(setCurrentTestHistory(response.data))
            }).catch((error) => {
                dispatch(postTestAttemptFailed())
            });
    }
}






