import React from "react";
import "./TableAnalysis.css";

function CommonAnalysisTable({ data, heading, style }) {
  // Function to safely get values from an object by key
  function getKeyValue(obj, key) {
    return obj[key] !== undefined ? obj[key] : "-"; // Return "-" if the key is not found
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="table-container" style={style}>
      {heading && <h2>{heading}</h2>}
      <table>
        <thead>
          <tr className="">
            {Object.keys(data[0].map).map((key) => (
              <th key={key}>{data[0].map[key]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data[0].questions.map((question, questionIndex) => {
            return (
              <tr key={questionIndex}>
                {Object.keys(data[0].map).map((key, keyIndex) => {
                  return (
                    <td
                      dangerouslySetInnerHTML={{
                        __html: getKeyValue(question, key),
                      }}
                    ></td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CommonAnalysisTable;
