import {
    dangerColor,
  } from "assets/jss/material-dashboard-react.js";

  import { makeStyles } from "@material-ui/core/styles";
  //.....styles..........

  const useStyles = makeStyles((theme) => ({
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      paddingTop: theme.spacing() * 5,
      [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
        width: 440,
        minHeight:550,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textAlign: 'center',
    },
    paper: {
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
    },
  
    form: {
      maxWidth: '328px',
      width: '100%', // Fix IE11 issue.
      marginTop: theme.spacing(),
    },
    
  }));
  
  export default useStyles;