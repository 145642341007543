import React, { useState, useEffect } from 'react';
import classNames from "classnames";
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
//@material-ui/core
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';

//Custom Components
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Button from "components/CustomComponents/CustomButtons/Button.js";
import SearchInput from "components/CustomComponents/Inputs/SearchInput/SearchInput";
import SkeletonCard from 'components/Views/QuestProducts/SkeletonCards'
// import Autocomplete from "components/CustomComponents/Autocomplete/Autocomplete"

// Views
import QuestDetails from 'components/Views/QuestProducts/Details/Details'
import SigninComponent from 'components/Views/SignIn/SigninSpectrum/SigninComponent'
import Signup from 'components/Views/SignUp/Signup'
import ProductsSwipeableViews from 'components/Views/QuestProducts/SwipeableViews/ProductsSwipeableViews'
import QuestSwipeableViews from 'components/Views/QuestProducts/SwipeableViews/QuestSwipeableViews'

// custom style
import useStyles from "assets/jss/components/views/questCategoryStyle";

//commonfunctions
import { clevertapEvents, facebookTracking } from "util/commonfunctions";
import { HIDE_FOR_IOS } from 'variables/general'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const CardConterol = (props) => {
    const theme = useTheme();
    return (
        <div>
            <Button justIcon color="white" size="sm" round onClick={props.onBack} disabled={props.activecard === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft />}
            </Button>

            <Button justIcon color="white" size="sm" round onClick={props.onNext} disabled={props.activecard === props.controlLength - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight />}
            </Button>
        </div>
    )
}

const CategoryCard = (props) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        < React.Fragment >
            {props.topics.length > 0
                && <div className={classes.buttonDiv}>
                    <Typography variant="body1" className={classes.topicsTitle}>
                        {props.task}
                    </Typography>
                    {sm && !md
                        ? null
                        : <CardConterol
                            onNext={handleNext}
                            onBack={handleBack}
                            activecard={activeStep}
                            controlLength={props.topics.length}
                        />
                    }
                </div>}


            {props.task === "Assigned Quest" ?
                <QuestSwipeableViews
                    activeStep={activeStep}
                    topics={props.topics}
                    stepChange={handleStepChange}
                    onSeeMore={props.onSeeMore}
                    onLaunch={props.onLaunch}
                    onOfflineLaunch={props.onOfflineLaunch}
                    onBuy={props.onBuy}
                    onClick={props.onClick}
                    onTrail={props.onTrail}
                />
                : <ProductsSwipeableViews
                    activeStep={activeStep}
                    topics={props.topics}
                    stepChange={handleStepChange}
                    onSeeMore={props.onSeeMore}
                    onLaunch={props.onLaunch}
                    onOfflineLaunch={props.onOfflineLaunch}
                    onBuy={props.onBuy}
                    onClick={props.onClick}
                    onTrail={props.onTrail}
                />
            }
        </React.Fragment >
    )
}


const QuestCategory = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();


    const platform = useSelector(state => state.platforms.platform)
    const userInfo = useSelector(state => state.user.userinfo)
    const cartItems = useSelector(state => state.store.cartitems)
    const currentQuest = useSelector(state => state.quest.current_quest)
    const boards = useSelector(state => state.boardgrade.boards)
    const gradeList = useSelector(state => state.boardgrade.grades)
    const productList = useSelector(state => state.products.productcategories)
    const questCategories = useSelector(state => state.user.userinfo.userQuestList.map(data => {
        data.isAssigned = true
        return data
    }))

    const backButtonClasses = classNames({
        [classes.backButtonContainer]: true,
        [classes.backButtonContainer1]: userInfo.lastQuestAccessed ? true : false
    })



    const [searchText, setSearchText] = useState("")
    const [loading, setLoading] = useState(false)
    const [questDialog, setQuestDialog] = React.useState(false);
    const [questData, setQuestData] = React.useState({ quest: null, isTrialQuest: false });
    const [loginDialog, setLoginDialog] = React.useState(false);
    const [signupDialog, setSignupDialog] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("danger");

    const [productCategories, setProductCategories] = React.useState({ productCategory: [] })

    const [board, setBoard] = React.useState(null);

    const [grades, setGrades] = React.useState([])
    const [grade, setGrade] = React.useState(null);

    /*-----------------------fetch Product Category --------------------*/
    useEffect(() => {
        fetchProductCategory()
    }, [userInfo.userId])


    const fetchProductCategory = async () => {
        setLoading(true)
        await dispatch(questActions.fetchProductCategory()).then(() => {
            setLoading(false)
            fetchBoardGrade()
        }).catch(error => {
            setLoading(false)
            setAlertType("danger")
            setAlertMessage(error.message)
        })
    }

    useEffect(() => {
        setProductCategories(productList)
    }, [productList.productCategory])


    const fetchBoardGrade = async () => {
        setLoading(true)
        await dispatch(questActions.fetchBoardGrade()).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        })
    }

    /*-----------------------on change Boards --------------------*/
    useEffect(() => {
        if (boards.length)
            if (currentQuest.gradeIds) {
                const currentGrade = gradeList.filter(data => data.gradeId === currentQuest.gradeIds[0]).reduce((acc, cv) => { return acc = cv }, {})
                setBoard(boards.filter(data => data.boardId === currentGrade.boardId).reduce((acc, cv) => { return acc = cv }, {}))
                setGrades(gradeList.filter(data => data.boardId === currentGrade.boardId))
                setGrade(currentGrade)
            }
    }, [boards])


    /*-----------------------On Change Grade--------------------*/
    useEffect(() => {
        if (grade) {
            const products = productList.productCategory.reduce((accumulator, currentValue) => {
                const productList = currentValue.productList.filter(d => d.gradeIds.includes(grade.gradeId))
                if (productList.length > 0) {
                    accumulator.push({
                        categoryName: currentValue.categoryName,
                        productList: productList
                    })
                }
                return accumulator
            }, [])

            setProductCategories({ productCategory: products })
        } else {
            setProductCategories(productList)
        }
    }, [grade])

    /*-----------------------  on Chnage Board --------------------*/
    const onChnageBoard = (event, newValue) => {
        setBoard(newValue)
        if (newValue)
            setGrades(gradeList.filter(data => data.boardId === newValue.boardId))
        else
            setGrades([])
        setGrade(null)
    }

    /*-----------------------handle Quest Dialog Open --------------------*/
    const openQuestDialog = (CurrentQuest) => {
        setQuestData({ ...questData, quest: CurrentQuest });
        setQuestDialog(true);
    };
    /*-----------------------handle Quest Dialog Close --------------------*/
    const closeQuestDialog = () => {
        setQuestDialog(false);
    };

    /*-----------------------handle Login Dialog Open --------------------*/
    const openLoginDialog = (CurrentQuest, isTrialQuest) => {
        setQuestData({ quest: CurrentQuest, isTrialQuest: isTrialQuest });
        setLoginDialog(true)
    };

    /*-----------------------handle Login Dialog Close --------------------*/
    const closeLoginDialog = () => {
        setLoginDialog(false);
    };

    /*-----------------------handle Signup Dialog Open --------------------*/
    const openSignupDialog = () => {
        closeLoginDialog();
        setSignupDialog(true)
    };

    /*-----------------------handle Signup Dialog Close --------------------*/
    const closeSignupDialog = () => {
        setSignupDialog(false);
    };

    /*----------------------- Change Quest Data --------------------*/
    const onChangeQuest = async (CurrentQuest, isTrialQuest = false) => {
        if (userInfo.userId) {
            assignQuest(CurrentQuest, isTrialQuest)
        } else {
            openLoginDialog(CurrentQuest, isTrialQuest)
        }
    }

    /*----------------------- Change Offline Quest --------------------*/
    const onChangeOfflineQuest = (URL) => {
        if (typeof window.Android !== 'undefined') {
            window.Android.openNewUrl("", URL)
        } else if (typeof openNewUrl !== 'undefined') {
            window.openNewUrl("", URL)
        } else if (typeof window.flutter_inappwebview !== 'undefined') {
            window.flutter_inappwebview.callHandler('openNewUrl', "", URL);
        } else {
            window.open(URL, "_blank")
        }
    }

    /*----------------------- Assign Quest --------------------*/
    const assignQuest = async (CurrentQuest, isTrialQuest, initUserInfo = true) => {
        setLoading(true)
        try {
            if (!CurrentQuest.isAssigned) {
                if (isTrialQuest) {

                    //facebook tracking
                    const EventProperty = JSON.stringify({
                        'fb_order_id': CurrentQuest.productId,
                        'fb_currency': ''
                    });
                    facebookTracking('StartTrial', EventProperty)

                    await dispatch(questActions.assignTrialProduct(CurrentQuest.productId)).then(async () => {
                        await setCurrentQuestAndInitContentList(CurrentQuest.questDetails[0], initUserInfo)
                        setLoading(false)
                    })


                } else {
                    await dispatch(questActions.assignFreeProduct(CurrentQuest.productId)).then(async () => {
                        await setCurrentQuestAndInitContentList(CurrentQuest.questDetails[0], initUserInfo)
                        setLoading(false)
                    })
                }
            } else {

                if (CurrentQuest.questName) {
                    await setCurrentQuestAndInitContentList(CurrentQuest, initUserInfo)
                } else {
                    await setCurrentQuestAndInitContentList(CurrentQuest.questDetails[0], initUserInfo)
                }

                setLoading(false)
            }
            props.history.push('/home')
        } catch (error) {
            setLoading(false)
            setAlertType("danger")
            setAlertMessage(error.message)
        }
    }

    const setCurrentQuestAndInitContentList = async (CurrentQuest, initUserInfo = false) => {

        //clevertap
        const eventData = JSON.stringify({
            'QuestId': CurrentQuest.questId,
            'QuestName': CurrentQuest.questName
        });
        clevertapEvents('QuestSelected', eventData);

        if (CurrentQuest.questName) {
            await dispatch(questActions.setCurrentQuest(CurrentQuest))
        } else {
            await dispatch(questActions.setCurrentQuestByID(CurrentQuest.questId, true))
        }
        await dispatch(questActions.initContentList(CurrentQuest.questId)).then(async () => {
            if (initUserInfo)
                await dispatch(questActions.initUserInfo())
        })
    }

    /*-----------------------handel Login --------------------*/
    const handelLogin = async () => {
        closeLoginDialog();
        assignQuest(questData.quest, questData.isTrialQuest);
    }

    /*-----------------------handel Signup --------------------*/
    const handelSignup = async () => {
        closeSignupDialog();
        setLoading(true)
        await assignQuest(questData.quest, questData.isTrialQuest);
    }

    //-----------------------Handel Buy Quest---------------------
    const onBuyQuest = async (CurrentQuest) => {
        const cartItem = {
            "cartId": "",
            "userId": userInfo.userId,
            "productId": CurrentQuest.productId,
            "productName": CurrentQuest.productName,
            'productIcon': CurrentQuest.productIcon,
            "qty": 1,
            "price": Number(CurrentQuest.price),
            "netPrice": Number(CurrentQuest.discountPrice)
        }

        //clevertap
        const eventData = JSON.stringify({
            'UserId': userInfo.userId,
            'ProductId': CurrentQuest.productId,
            'ProductName': CurrentQuest.productName
        });
        clevertapEvents('PurchaseInitiate', eventData);

        //facebookTracking
        const EventProperty = JSON.stringify({
            'fb_content_type': CurrentQuest.productName,
            'fb_content_id': CurrentQuest.productId,
            'fb_num_items': 1,
            'fb_payment_info_available': false,
            'fb_currency': ''
        });
        facebookTracking('fb_mobile_initiated_checkout', EventProperty)

        await dispatch(questActions.addToCart(cartItem));
        props.history.push('/cart',{backTo:"/products"});
    }

    //-----------------------Handel Handel Trail---------------------
    const handelTrail = (CurrentQuest) => {
        onChangeQuest(CurrentQuest, true)
        // setQuestData({ ...questData, quest: CurrentQuest });
        // setAlertType("trial")
        // setAlertMessage(`Do you want to continue ${CurrentQuest.trialDuration}-Days Trial`)
    }


    ///----------------------- Close Alert ---------------------
    const onCloseAlert = () => {
        setAlertMessage("")
    }

    ///----------------------- Ok Alert ---------------------
    const onOkAlert = () => {

        switch (alertType) {
            case "buy":
                onBuyQuest(questData.quest)
                setAlertMessage("")
                break;
            case "trial":
                onChangeQuest(questData.quest, true)
                setAlertMessage("")
                break;
            default:
                setAlertMessage("")
        }
    }


    // --------------------------- on Launch Last Quest Accessed ----------------------------
    const onLaunchLastQuestAccessed = async (questId) => {
        await onChangeQuest(questCategories.filter(data => data.questId === questId)[0]);
    }


    // --------------------------- handel on click Card----------------------------
    const handelCardClick = (CurrentQuest) => {
        setQuestData({ ...questData, quest: CurrentQuest });
        if (CurrentQuest.isFree) {
            if (CurrentQuest.isOnline === true || CurrentQuest.isOnline === undefined || CurrentQuest.isOnline === null)
                onChangeQuest(CurrentQuest)
            else
                onChangeOfflineQuest(CurrentQuest.formUrl)
        } else {
            if (CurrentQuest.isTrialTaken) {
                setAlertType("buy")
                setAlertMessage(`Do you want to continue with buy this product.`)
                // onBuyQuest(CurrentQuest)
            }

            else
                if (CurrentQuest.isAssigned)
                    onChangeQuest(CurrentQuest)
                else
                    if (CurrentQuest.isOnline === true || CurrentQuest.isOnline === undefined || CurrentQuest.isOnline === null)
                        if (CurrentQuest.trialDuration > 0) {
                            handelTrail(CurrentQuest)
                        }
                        else
                            onBuyQuest(CurrentQuest)
                    else
                        onChangeOfflineQuest(CurrentQuest.formUrl)
        }
    }



    return (

        <div className='page-container-0 background-page-container-0'>
            <CssBaseline />
            {/*-----Dialog----- */}
            <Dialog scroll="body" open={questDialog} TransitionComponent={Transition} fullWidth={true} classes={{ paper: classes.paper }} maxWidth="xs" >
                <QuestDetails
                    data={questData.quest}
                    onClose={closeQuestDialog}
                    onLaunch={onChangeQuest}
                    onOfflineLaunch={onChangeOfflineQuest}
                    onBuy={onBuyQuest}
                />
            </Dialog>

            {/* Signin */}
            <Dialog scroll="body" open={loginDialog} TransitionComponent={Transition} fullWidth={true} classes={{ paper: classes.paper }} maxWidth="xs" onClose={closeLoginDialog}>
                <SigninComponent onLogin={handelLogin} onSignUp={openSignupDialog} isInitCurrentQuestAndContentList={false}
                />
            </Dialog>

            {/* Signup */}
            <Dialog scroll="body" open={signupDialog} TransitionComponent={Transition} fullScreen   >
                <Signup onSignUp={handelSignup} onClose={closeSignupDialog} isLoginAfterSignUp={true} />
            </Dialog>

            {/* AlertDialog */}
            <AlertDialog
                maxWidth="xs"
                open={alertMessage ? true : false}
                type={(alertType === "buy" || alertType === "trial") ? 'info' : alertType}
                ContentText={alertMessage}
                ContentTextStyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titleStyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={
                    (alertType === "buy" || alertType === "trial")
                        ? [
                            { text: 'not now', onPress: () => onCloseAlert() },
                            { text: 'continue', onPress: () => onOkAlert() }
                        ]
                        : [
                            { text: 'OK', onPress: () => onCloseAlert() }
                        ]
                }
            />



            {(loading)
                ? <SkeletonCard />
                : <GridContainer>
                    <GridItem xs={12} sm={12} md={12} >
                        <div style={{ padding: 30 }} >
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} className={classes.searchTextContainer}>
                                    <GridContainer className={classes.boardGradeSearchContainer}>
                                        <GridItem xs={10} sm={4} md={4} lg={4}>
                                            <div className={backButtonClasses}>
                                                <IconButton style={{ display: userInfo.userId ? !userInfo.lastQuestAccessed ? "none" : "visible" : "visible", color: "#fff" }} onClick={() => props.history.push(userInfo.lastQuestAccessed ? '/home' : '/')}>
                                                    <ArrowBackIcon />
                                                </IconButton>
                                                <SearchInput
                                                    inputchange={(text) => setSearchText(text)}
                                                />
                                            </div>
                                        </GridItem>

                                        <GridItem xs={10} sm={3} md={3} lg={3}>
                                            <Autocomplete
                                                options={boards}
                                                value={board}
                                                onChange={onChnageBoard}
                                                getOptionLabel={(option) => option.boardName ? option.boardName : ""}
                                                renderInput={(params) => <TextField {...params} label="Select Board" margin='dense' variant="outlined" />}
                                            />

                                        </GridItem>

                                        <GridItem xs={10} sm={3} md={3} lg={3}>
                                            <Autocomplete
                                                options={grades}
                                                value={grade}
                                                onChange={(event, newValue) => { setGrade(newValue) }}
                                                getOptionLabel={(option) => option.gradeName ? option.gradeName : ""}
                                                renderInput={(params) => <TextField {...params} label="Select Grade" margin='dense' variant="outlined" />}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>


                                {platform === "IOS" && HIDE_FOR_IOS
                                    ? null
                                    : cartItems.length > 0 ?
                                        <div className={classes.cartIconContainer}>
                                            <IconButton onClick={() => props.history.push('/cart')} style={{ color: "#fff" }}>
                                                <Badge badgeContent={cartItems.length} color="primary">
                                                    <ShoppingCartTwoToneIcon />
                                                </Badge>
                                            </IconButton>
                                        </div>
                                        : null
                                }
                            </GridContainer>
                            {userInfo.lastQuestAccessed && <Card className={classes.cardStyle} style={{ margin: 10, padding: 10 }}>
                                <CardHeader
                                    action={
                                        <Button className={classes.button} color="primary" size="sm" onClick={() => onLaunchLastQuestAccessed(userInfo.lastQuestAccessed)} >
                                            Launch
                                        </Button>
                                    }
                                    title={userInfo.lastQuestName}
                                    titleTypographyProps={{ variant: 'body1', className: classes.contentCardTitle }}
                                    subheader={`Viewed on date: ${userInfo.viewedOn}`}
                                    subheaderTypographyProps={{ variant: 'caption' }}
                                />
                            </Card>
                            }
                            {/* Assigned Courses */}
                            <CategoryCard
                                task={'Assigned Quest'}
                                topics={questCategories.filter(quest => quest.questName.trim().toUpperCase().includes(searchText.trim().toUpperCase())).map(data => {
                                    data.isAssigned = true
                                    return data;
                                })}
                                onSeeMore={openQuestDialog}
                                onLaunch={onChangeQuest}
                                onOfflineLaunch={onChangeOfflineQuest}
                                onBuy={onBuyQuest}
                                onTrail={handelTrail}
                                onClick={handelCardClick}
                            />
                            {/* Products */}
                            {platform === "IOS" && HIDE_FOR_IOS
                                ? null
                                : productCategories.productCategory.map((data, idex) => (
                                    <CategoryCard
                                        key={"CategoryCard" + idex}
                                        task={data.categoryName}
                                        topics={data.productList.filter(quest => quest.productName.trim().toUpperCase().includes(searchText.trim().toUpperCase()))}
                                        onSeeMore={openQuestDialog}
                                        onLaunch={onChangeQuest}
                                        onOfflineLaunch={onChangeOfflineQuest}
                                        onBuy={onBuyQuest}
                                        onTrail={handelTrail}
                                        onClick={handelCardClick}
                                    />))

                            }
                        </div>
                    </GridItem>
                </GridContainer>
            }
        </div>
    )
}

export default QuestCategory;