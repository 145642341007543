// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import {
    roseColor,
    grayColor,
    boxShadow,
} from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        justifyContent: "center"
    },
    activeCard: {
        marginBottom: "10px",
        marginTop: "10px",
        borderRadius: 10,

        ...boxShadow
    },
    deactivateCard: {
        marginBottom: "10px",
        marginTop: "10px",
        backgroundColor: '#F2F8FF',
        opacity: 0.8,
        borderRadius: 10,
        ...boxShadow
    },
    contentCardGridContainer: {
        margin: 0
    },
    contentCardGridItem: {
        padding: '0 10px'
    },

    contentText1: {
        margin: '0 8px 0 0',
        display: 'flex',
        padding: '0px 0.1rem 0px 0.2rem',
        alignItems: 'flex-start',
        padding: 3,
        paddingRight: 4,
        flexDirection: 'column',
        width: '100%'
    },
    contentText2: {
        textAlign: 'center',
        padding: 10,
        backgroundColor: grayColor[4]
    },

    contentOP: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },

    webTopicCard: {
        height: '100%',
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        margin: 0
    },

    cardActionArea: {
        height: 150,
        display: "flex",
        backgroundColor: ' #D4DFF9'
    },
    cardMediaImg: {
        width: '100%',
        maxWidth: 150,
        height: 'auto',
        // padding:15,
        objectFit: 'fill !important',
    },
    cardMedia: {
        width: '100% !important',
        maxWidth: 150,
        height: 'inherit',
        objectFit: 'scale-down !important',
    },

    // temp
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: roseColor[0],
    },

    //one line height
    topicTitle: {
        fontWeight: 'bold',
        color: '#000000',
        padding: '0 15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },


    //one line height
    operationCardTitle: {
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // height: '3em',
        display: '-webkit-box',
        /* line-height: 16px; */
        /* max-height: 32px; */
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            lineHeight: 1.2,
            letterSpacing: '0.03333em'
        }
    },

    //two line height
    contentCardTitle: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        height: '2.5em',
        lineHeight: 1.2,
        letterSpacing: '0.03333em',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            // height: '2.5em',
            // lineHeight: 1.2,
            fontSize: '0.75rem',
            // letterSpacing: '0.03333em',
        }
    },
    textPointAndTime: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.625rem',
            lineHeight: 1.2,
            letterSpacing: '0.03333em'
        }
    },

    //GridContainer for mobile view
    mobileGridContainer: {
        margin: 0,
        height: 'inherit',
        overflow: 'auto'
    },
    //HiddenmdUp
    mdUpHiddenStyle: {
        height: '100vh',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },

    //ContentCard.js
    contentCardStyle: {
        width: '100%',
        // maxWidth: 150,
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 10,
        cursor: 'pointer',
        boxShadow: '0 2px 2px -5px rgba(0, 0, 0, 0.42), 0 1px 10px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
    },
    contentStyle: {
        padding: '7px 7px 5px 7px !important',
        [theme.breakpoints.down('sm')]: {
            padding: '7px 7px 5px 7px !important'
        }
    },

    ptAndTmConatiner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },

    //Operation.js
    operationContentCard: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 5,
        paddingBottom: '0px !important'
    },
    countCardIcon: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: 3,
        color: '#fff'
    },
    countIcon:{
        fontSize: '0.85rem',
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.65rem'
        }
    },
    countCardText: {
        fontSize:'0.625rem',
        fontWeight: 'bold',
        color: '#fff',
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.563rem'
        }
    },
    countCardTitle: {
        fontWeight: 'bold', 
        color: '#fff', 
        lineHeight: 1, 
        paddingLeft: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.625rem'
        }
    },

    divPageNavigation: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: 10
    },
    joinMasterClassCard:{
        boxShadow: '0px 2px 10px 0px  rgba(0,0,0,0.15)',
        borderRadius: '5px',
        padding: '50px',
        // width: '400px',
        textAlign: 'center',
        cursor: 'pointer',
        position:'relative',
    },
    joinNow:{
        border:'none',
        background: 'linear-gradient(264.15deg, #4F90EE 0%, #0945BF 100%)',
        padding:'12px 28px 12px 28px',
        cursor:'pointer',
        borderRadius:'6px',
        color:'#fff',
        fontSize:'16px',
    },
    joinMasterClassText:{
        fontSize:'24px',
        fontWeight:'500',
        color:'#425466'
    },
    logoContainer:{
        position:'absolute',
        top:'10px',
        left:'10px',
    },
    blinkingRedDot: {
        width: '10px',
        height: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        animation: '$blink 1s infinite'
    },
    '@keyframes blink': {
        '0%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        }
    },
    liveclass:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'5px',
        backgroundColor:'#fff',
        borderRadius:'4px',
        padding:'2px 10px',
        cursor:'pointer',
        border:'1px solid red',
        fontWeight:'bold',
    },
    instructionContainer:{
        marginTop:'40px',
        padding:'5px',
        border:'1px solid #ccc',
        borderRadius:'5px',

        '& h3':{
            fontWeight:'bold',
            color:'#000',
            marginBottom:'10px',
            padding:'5px',
            borderBottom:'1px solid #ccc',

        }, 
        '& ul':{
            padding:'5px',
            margin:'0',
            listStyle:'none',
            textAlign:'left',
        },
        '& li':{
            padding:'5px',
            margin:'0',
            listStyle:'none',
            textAlign:'left',
        }
    }
}))

export default useStyles;