import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    notifications: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATION:
            return {
                ...state,
                notifications: action.notifications.notification ? action.notifications.notification : [],
            }

        default:
            return state;
    }

}


export default reducer