// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: "center"
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.secondary.main,
        marginLeft: 'auto',
        marginRight: 'auto'

    },
}))

export default useStyles;