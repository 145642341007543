import moment from 'moment'
//export const TODAY_DATE_TIME = new Date().toISOString().replace(/T/, ' '). replace(/\..+/, '')     // delete the dot and everything after
export const CURRENT_DATE_TIME = () => moment().utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss')   // delete the dot and everything after

export const LENGTH_ERROR = (length) => `Length should be less than ${length} characters`
export const MIN_LENGTH_ERROR = (text, length) => `${text} must be at least ${length} characters`

export const HIDE_FOR_IOS = true // temporary hide features for ios 


// Share URL 
export const SHARE_URL_ANDROID =(UserId)=>`https://play.google.com/store/apps/details?id=com.zl.questwebapp&utm_source=APP&utm_medium=Share&utm_campaign=${UserId}`;
export const SHARE_URL_IOS=()=>'https://apps.apple.com/in/app/quest/id1542442386'