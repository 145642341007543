import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player'

//import reducer 
import { useDispatch, useSelector } from 'react-redux';
import * as questActions from 'store/actions/index'

// @material-ui/core
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import CardIcon from "components/CustomComponents/Card/CardIcon.js";

// Views
import VideoGallery from 'components/CustomComponents/VideoGallery/VideoGallery'

// styles
import useStyles from "assets/jss/components/views/questDetailsStyle"

const QuestDetails = (props) => {


    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const productDetails = useSelector(state => state.products.productdetails)

    const isSamePrice = (Math.floor(props.data.discountPrice) === Math.floor(props.data.price))

    useEffect(() => {
        setIsLoading(true);
        dispatch(questActions.fetchProductDetails(props.data.productId)).then(() => {
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [dispatch]);

    const handleClose = () => {
        dispatch(questActions.setQuestDetails({}))
        props.onClose()
    }

    const handelLaunch = () => {
        handleClose()
        props.onLaunch(props.data);
    }

    const _handelLaunch = () => {
        handleClose()
        props.onOfflineLaunch(props.data.formUrl)
        //window.open(props.data.formUrl, "_blank")
    }
    const handelBuy = () => {
        handleClose()
        props.onBuy(props.data);
    }

    const handelTrail = () => {
        handleClose()
        props.onLaunch(props.data, true);
    }


    return (
        <React.Fragment>
            <DialogTitle>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                        <Typography variant="subtitle1" component='h3' gutterBottom style={{ fontWeight: 'bold' }}>{props.data.productName}</Typography>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        {isLoading
                            ? <Skeleton variant="rect" width={'100%'} height={200} animation="wave" />
                            : productDetails.productVideo
                                ? <VideoGallery data={productDetails.productVideo} />
                                : productDetails.productIcon && <CardIcon className={classes.imgIcon}>
                                    <img className={classes.imageStyle} src={productDetails.productIcon} alt="..." />
                                </CardIcon>
                        }
                    </GridItem>
                </GridContainer>
            </DialogTitle>

            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} className={classes.infoText}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Typography variant="subtitle1" component='h3' gutterBottom style={{ fontWeight: 'bold' }}>
                                    {isLoading ? <Skeleton animation="wave" /> : 'About the Quest'}
                                </Typography>
                                {isLoading
                                    ? <div>
                                        <Skeleton animation="wave" />
                                        <Skeleton animation="wave" />
                                        <Skeleton animation="wave" />
                                        <Skeleton animation="wave" />
                                    </div>
                                    : <div
                                        style={{ marginTop: -15, textAlign: 'justify' }}
                                        dangerouslySetInnerHTML={{
                                            __html: productDetails.productDescription
                                        }}
                                    ></div>
                                }
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    {((props.data.isFree || props.data.isAssigned) ^ !(props.data.isOnTrial || props.data.isTrialTaken))
                        ? <GridItem xs={12} sm={12} md={12} className={classes.infoText}>
                            <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 'bold' }}>
                                {isLoading
                                    ? <Skeleton animation="wave" />
                                    : <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {"Price :"}
                                        {props.data.isFree
                                            ? "Free"
                                            : isSamePrice
                                                ? <div>
                                                    <samp style={{ fontWeight: "normal", paddingLeft: 5 }}>₹</samp>
                                                    {Math.floor(props.data.discountPrice) + ''}
                                                </div>
                                                : <Typography
                                                    variant="subtitle1"
                                                    style={{ fontWeight: 'bold', lineHeight: 1 }}>
                                                    <samp style={{ fontWeight: "normal", paddingLeft: 5 }}>₹</samp>
                                                    {Math.floor(props.data.discountPrice) + ''}

                                                    <div>
                                                        <Typography variant="caption" className={isSamePrice ? classes.priceText : `line-through ${classes.priceText}`}>
                                                            <samp style={{ fontWeight: "normal", paddingLeft: 5 }}>₹</samp>
                                                            {Math.floor(props.data.price) + ""}
                                                        </Typography>
                                                    </div>
                                                </Typography>
                                        }
                                    </div>
                                }

                            </Typography>


                        </GridItem>
                        : null}

                </GridContainer>
            </DialogContent>

            <DialogActions style={{ margin: 10, marginTop: -10 }}>
                {
                    isLoading ? <Skeleton animation="wave" />
                        : props.data.isFree
                            ? props.data.isOnline
                                ? <Button size='md' fullWidth color="primary" onClick={handelLaunch}> Launch</Button>
                                : <Button size="md" fullWidth color="info" onClick={_handelLaunch}  >I am interested</Button>
                            : props.data.isTrialTaken
                                ?
                                // props.data.isAssigned
                                //     ? <Button size='md' fullWidth color="primary" onClick={handelLaunch}> Launch</Button>
                                //     : 
                                <Button size='md' startIcon={<ShoppingCartTwoToneIcon style={{ marginRight: -5 }} />} fullWidth color="rose" onClick={handelBuy} >Buy</Button>
                                : props.data.isAssigned
                                    ? <Button size='md' fullWidth color="primary" onClick={handelLaunch}> Launch</Button>
                                    : props.data.isOnline === true || props.data.isOnline === undefined || props.data.isOnline === null
                                        ? <React.Fragment>
                                            {props.data.trialDuration > 0
                                                ? <Button size='md' fullWidth color="warning" onClick={handelTrail} >{`${props.data.trialDuration}-Days Trial`} </Button>
                                                : null
                                            }
                                            <Button size='md' startIcon={<ShoppingCartTwoToneIcon style={{ marginRight: -5 }} />} fullWidth color="rose" onClick={handelBuy} >Buy</Button>
                                        </React.Fragment>
                                        : <Button size="md" fullWidth color="info" onClick={_handelLaunch}  >I am interested</Button>
                }

            </DialogActions>
        </React.Fragment>



    );
}





export default QuestDetails;
