import {
  whiteColor,
  primaryColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = {
  infoText: {
    color: primaryColor[4]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    textAlign: 'center'
  },
  taskDiv: {
    padding: '0px 20px 0px 10px',
    borderRightStyle: 'groove',
    borderWidth:1,
    borderRightColor: primaryColor[0]
  },
  timeDiv: {
    padding: '0px 0px 0px 10px',
  },

  pageCardBody: {
    minHeight: '100vh'
  },
  gridItemFooter: {
    padding: '0 !important'
  },
  cardMissions: {
    marginBottom: "0",
    marginTop: '0'
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardAvatar: {
    maxWidth: "70px",
    maxHeight: "70px",
    right: "10%",
    position: 'absolute',
    margin: "-35px auto 0",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0",
    // boxShadow:
    //   "0 16px 38px -12px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.56), 0 4px 25px 0px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.12), 0 8px 10px -5px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.2)",
    // "&$cardAvatarPlain": {
    //   marginTop: "0"
    // }
  },
  imgIcon:{
width:0,
height:90
  },
  imageStyle: {
    width: "auto",
    height: "-webkit-fill-available"
  },
  checkboxStyle: {
    // padding:'7px 30px'
  },
  checkboxLabelStyle: {
    fontSize: '0.9rem',
    color: primaryColor[4],
    fontWeight: "bold",
    textTransform: "capitalize",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
  },
  earnPoints: {
    padding: '5px 20px 5px 20px',
    width: 'fit-content',
    borderStyle: 'solid',
    textAlign: 'center',
    Display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    // marginTop: '30px',
    marginBottom:'24px',
    borderRadius: '30px',
    borderWidth:1,
    borderColor: primaryColor[4]
  }


};

export default dashboardStyle;
