import React from "react";
import PropTypes from "prop-types";

//Redux
import { useSelector, useDispatch } from 'react-redux'
import * as questActions from 'store/actions/index'

import Hidden from "@material-ui/core/Hidden";

// core components
import Slide from '@material-ui/core/Slide'
import WebNavbarLinks from "./WebNavbarLinks.js";
import MobileNavbarLinks from './MobileNavbarLinks'
import CustomizedDialog from "components/CustomComponents/Dialog/CustomizedDialog";

// Views
import LeaderBoard from 'components/Views/LeaderBoard/LeaderBoard'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Header(props) {
  const dispatch = useDispatch()
  const infoLoading = useSelector(state => state.user.loading)
  const userInfo = useSelector(state => state.user.userinfo)


  const leaderboardDialog = useSelector(state => state.leaderboard.show_leader_board_dialog)
  const leaderboardLoading = useSelector(state => state.leaderboard.leader_board_data_loading)
  const leaderboard = useSelector(state => state.leaderboard.leader_board_data)


  /*-----------------------handle Leader Board Close --------------------*/
  const handleCloseLeaderBoardDialog = () => {
    dispatch(questActions.showLeaderBoard(false));
  };

  /*-----------------------handle Leader Board Open --------------------*/
  const handleOpenLeaderBoardDialog = () => {
    dispatch(questActions.showLeaderBoard(true))
  };



  return (

    <React.Fragment>

      {/*-----Dialog Leaderboard ----- */}
      <CustomizedDialog
        title={"Leader Board"}
        open={leaderboardDialog}
        maxWidth={"xs"}
        fullWidth={true}
        scroll="paper"
        TransitionComponent={Transition}
        onClose={handleCloseLeaderBoardDialog}
      >
        <LeaderBoard
          data={leaderboard}
          loading={leaderboardLoading}
          onClose={handleCloseLeaderBoardDialog}
        />
      </CustomizedDialog>


      <Hidden smDown implementation="css">
        <WebNavbarLinks 
        {...props} 
          loading={infoLoading}
          userInfo={userInfo}
          openLeaderBoard={handleOpenLeaderBoardDialog}
        />
      </Hidden>

      <Hidden mdUp implementation="css">
        <MobileNavbarLinks
          color="gray"
          {...props}
          loading={infoLoading}
          userInfo={userInfo}
          openLeaderBoard={handleOpenLeaderBoardDialog} />
      </Hidden>
    </React.Fragment>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
