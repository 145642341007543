import React, { useState } from 'react';
//@material-ui/core
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
//Views
import TopicCards from 'components/Views/Tasks/Topics/TopicCards'

// styles
import useStyles from "assets/jss/components/views/taskStyle";

const AutoPlaySwipeableViews = (props) => {
    const { topics} = props
    const classes = useStyles();
    return (
        <GridContainer className={classes.contentCardGridContainer}>
            {topics.map((step, index) => (
                <GridItem xs={6} sm={6} md={3} key={step.contentId + index} className={classes.contentCardGridItem}>
                    <TopicCards {...step} setContentType={props.setContentType}/>
                </GridItem>
            ))}
        </GridContainer>
    )
};

export default AutoPlaySwipeableViews;