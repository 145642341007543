import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
//@material-ui/core
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
//material-ui/icons/
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
//Lottie
import Lottie from 'react-lottie';
import ShoppingCartLottie from 'assets/lotties/shopping-cart';

// Image
import DefaultImage from "assets/img/task/default.png";
import MembershipImage from "assets/img/Other/Membership-card.png";
import ProMembershipImage from "assets/img/Other/Membership-card-pro.png";

// custom style
import useStyles from "assets/jss/components/views/cartStyle";

const CartLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: ShoppingCartLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        <Typography variant="h5" component="h2" style={{ marginBottom: 10 }}>
            <Skeleton animation="wave" />
        </Typography>
        {['IT0011', 'ITM0101', 'IPS0111', 'ITP1111'].map((v, i) =>
            <Card style={{ marginBottom: 30, borderRadius: 10, }} key={v}>
                <CardContent style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton animation="wave" variant="rect" width={128} height={70} />
                    <div style={{ width: '100%', padding: 10 }}>
                        <Skeleton animation="wave" variant="rect" />
                        <div style={{ height: 5 }}></div>
                        <Skeleton animation="wave" variant="rect" height={30} />
                    </div>
                </CardContent>
            </Card>
        )}
    </React.Fragment>
    )
}



const ItemCard = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const {
        data,
        onBuy,
        loading,
        ...rest
    } = props;

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }

    return (

        <React.Fragment >
            {data.length ?
                <React.Fragment>
                    <Typography className={classes.lengthTitle} variant="body1" component="h2" gutterBottom>
                        {data.length} Products
                    </Typography>

                    {
                        data.map((product, idex) => (
                            <div className={classes.root} key={product.productId + "_" + product.planId}>
                                <Paper className={classes.paper}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} container spacing={1}>
                                            <Grid item xs={12} sm={12} md={2} >
                                                <ButtonBase className={classes.image}>
                                                    {/* <img className={classes.img} alt="complex" src={product.productIcon ? product.productIcon : DefaultImage} /> */}
                                                    {product.planPrice > 600
                                                        ? <img className={classes.img} alt="complex" src={ProMembershipImage} />
                                                        : <img className={classes.img} alt="complex" src={MembershipImage} />
                                                    }
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={10}>
                                                <Typography gutterBottom variant="subtitle1" className={classes.nameTitle} >
                                                    {product.productDisplayName}
                                                </Typography>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.productDescription
                                                    }} />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} container style={{ marginTop: 10 }}>
                                            <Grid item xs style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="subtitle2" className={classes.priceTitle}>
                                                        <samp style={{ fontWeight: "normal" }}>₹</samp>{Math.floor(product.planPrice * 100 / 118)}
                                                    </Typography>
                                                    <div className={classes.gstFontStyle}>Plus 18% GST</div>
                                                </div>
                                                <Button
                                                    className={classes.button}
                                                    style={{ marginLeft: 3 }}
                                                    color="rose"
                                                    size="xs"
                                                    onClick={() => props.onBuy(product)} >
                                                    Buy
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        ))
                    }
                </React.Fragment>

                : <div style={{ justifyContent: "center", display: 'flex' }}>
                    <CardContent style={{ justifyContent: "center", display: 'flex', flexDirection: 'column', textAlign: 'center', color: "#fff" }}>
                        <Lottie options={CartLottieOptions} height={200} width={200} />
                        <Typography variant="h6" style={{ color: "inherit" }} gutterBottom={true}>
                            {"No Products!"}
                        </Typography>
                        {/* <Button
                            className={classes.buttonKeepShop}
                            color="primary"
                            size='normal'
                            onClick={() => history.push('/products')} >Keep shopping</Button> */}
                    </CardContent>
                </div>
            }
        </React.Fragment>
    );
}

export default ItemCard;