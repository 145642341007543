import * as actionTypes from '../actionTypes';
import axiosConfig from "util/axios";

//---Missions Schedules---
export const setMissionSchedules = (SCHEDULE) => {
    return {
        type: actionTypes.SET_MISSIONS_SCHEDULES,
        schedule: SCHEDULE
    }
}

export const fetchMissionSchedules = (Missionid) => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('QuestSchedule/GetTrialSchedules', {
            params: {
                UserId: UserId,
                Missionid: Missionid
            }
        }).then(response => {
            dispatch(setMissionSchedules(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

