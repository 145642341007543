import React, { useEffect, useState } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom'
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//@material-ui/core
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
// import material-ui/icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import ItemCard from 'components/Views/ShoppingCart/ItemCard'
import CheckoutCard from 'components/Views/ShoppingCart/CheckoutCard'
import Progress from 'components/CustomComponents/Progress/Progress'
import CustomizedDialog from "components/CustomComponents/Dialog/CustomizedDialog";
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
// Views
import EasySignup from 'components/Views/SignUp/EasySignup'
import SigninComponent from 'components/Views/SignIn/SigninSpectrum/SigninComponent'
import UserDetails from 'components/Views/UserDetails/UserDetailsPopup'
// custom style
import useStyles from "assets/jss/components/views/cartStyle";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const ShoppingCart = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const cartItems = useSelector(state => state.store.cartitems)
    const PGData = useSelector(state => state.store.PGData)
    const paymentStatus = useSelector(state => state.store.paymentStatus)
    const userInfo = useSelector(state => state.user.userinfo)
    const couponStatus = useSelector(state => state.store.couponStatus)
    const couponCode = useSelector(state => state.store.couponCode)


    const [loading, setLoading] = useState(false)
    const [progressLoading, setProgressLoading] = useState(false)
    const [signupDialog, setSignupDialog] = React.useState(false);
    const [detailsDialog, setDetailsDialog] = React.useState(false);
    const [loginDialog, setLoginDialog] = React.useState(false);
    const [payableAmount, setPayableAmount] = React.useState(0);
    const [openPaymentStatus, setOpenPaymentStatus] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("success");

    const [coupon, setCoupon] = useState("");

    useEffect(() => {
        const paramOrderId = location.pathname.split("/")[2];
        if (userInfo.userId) {
            if ((paramOrderId !== "" && paramOrderId !== undefined) && (PGData.orderId !== "" && paramOrderId !== undefined) && (paramOrderId === PGData.orderId)) {
                dispatch(questActions.checkPaymentStatus(paramOrderId))
            }
            getCartDetails()
        }
    }, [dispatch])

    useEffect(() => {
        if (!paymentStatus.isSuccess && paymentStatus.errorCode === 'Failed') {
            setOpenPaymentStatus(true)
            dispatch(questActions.setPaymentGatwayDetails())
            dispatch(questActions.setPaymentStatus())
            setTimeout(() => {
                setOpenPaymentStatus(false)
            }, 6000)
        } else if (paymentStatus.isSuccess && paymentStatus.errorCode === 'Success') {
            dispatch(questActions.setPaymentGatwayDetails())
            dispatch(questActions.setPaymentStatus())
            getCartDetails();
            history.push('/payment-response')
        }
    }, [paymentStatus])

    useEffect(() => {
        const AMOUNT = cartItems.reduce((totalAmount, currentValue) => totalAmount + Number(currentValue.netPrice), 0)
        setPayableAmount(AMOUNT.toFixed(2))
    }, [cartItems])

    /*----------------------- Get Cart Details --------------------*/
    const getCartDetails = async () => {
        setLoading(true)
        dispatch(questActions.fetchCartItems()).then(() => {
            setLoading(false)
        })
    }

    /*-----------------------handle Login Dialog Open --------------------*/
    const openLoginDialog = () => {
        setLoginDialog(true)
    };

    /*-----------------------handle Login Dialog Close --------------------*/
    const closeLoginDialog = () => {
        setLoginDialog(false);
    };

    /*------------------handle SignUp Dialog Close --------------------*/
    const closeSignUpDialog = () => {
        setSignupDialog(false);
    };

    /*-----------------------handel Login --------------------*/
    const handelLogin = async () => {
        closeLoginDialog();
        setLoading(true)
        await dispatch(questActions.fetchCartItems())
        dispatch(questActions.addItemsToCart()).then(() => {
            getCartDetails()
        })
    }
    const _handelLogin = () => {
        closeSignUpDialog();
        setProgressLoading(true)
        setTimeout(() => {
            setProgressLoading(false)
            setLoginDialog(true)
        }, 1000)
        //props.history.push('/signup')
    }
    /*-----------------------Handel Sign Up--------------------*/
    const handelSignUp = async () => {
        closeSignUpDialog();
        setAlertType("success")
        setAlertMessage("Registration completed successfully!")
        await dispatch(questActions.fetchCartItems())
        dispatch(questActions.addItemsToCart()).then(() => {
            getCartDetails()
        })
    }

    const _handelSignUp = () => {
        closeLoginDialog();
        setProgressLoading(true)
        setTimeout(() => {
            setProgressLoading(false)
            setSignupDialog(true)
        }, 1000)
        //props.history.push('/signup')
    }

    /*-----------------------Handel Checkout--------------------*/
    const handelCheckout = () => {
        if (userInfo.userId) {
            setDetailsDialog(true)
            // dispatch(questActions.getPaymentGatwayDetails())
        } else {
            openLoginDialog()
        }
    }
    /*-----------------------Remove Item--------------------*/
    const handelRemoveItem = (item) => {
        setLoading(true)
        dispatch(questActions.removeItemFromCart(item)).then(() => {
            setLoading(false)
        })
    }

    /*-------------------onCloseAlert--------------------*/
    const onCloseAlert = () => {
        setAlertMessage("")
    }

    /*-------------------handel Apply Coupon--------------------*/
    const handelApplyCoupon = () => {
        setProgressLoading(true)
        dispatch(questActions.applyStoreCoupon(coupon)).then(() => {
            setProgressLoading(false)
        }).catch(error => {
            setProgressLoading(false)
            setAlertType("error")
            setAlertMessage(error.message)
        })
    }

    /*-------------------handel Apply Coupon--------------------*/
    const handelRemoveCoupon = () => {
        dispatch(questActions.setStoreCouponStatus({}))
        dispatch(questActions.setStoreCouponCode(""))
    }


    /*-------------------handel Update Details-------------------*/
    const handelUpdateDetails = () => {
        setDetailsDialog(false)
        dispatch(questActions.getPaymentGatwayDetails())
    }

    return (
        <div className='signin-container background-home'>
            <CssBaseline />

            {/*----- Spinner---- */}
            {progressLoading ? <Progress color="secondary" open={progressLoading} /> : null}


            {/* ----Signin Dialog------ */}
            <CustomizedDialog
                title={"Log In to Your Quest Account!"}
                fullScreen={fullScreen}
                scroll="body"
                open={loginDialog}
                TransitionComponent={Transition}
                fullWidth={true}
                classes={{ paper: classes.paperDialog }}
                maxWidth="xs"
                onClose={closeLoginDialog}
            >
                <div style={{ marginTop: -19 }}>
                    <SigninComponent onLogin={handelLogin} onSignUp={_handelSignUp} isLoginAfterSignUp={true} />
                </div>

            </CustomizedDialog>

            {/* ----Signup Dialog------ */}
            <CustomizedDialog
                title={"Sign Up and Start Learning!"}
                fullScreen={fullScreen}
                scroll="body"
                open={signupDialog}
                TransitionComponent={Transition}
                fullWidth={true}
                classes={{ paper: classes.paperDialog }}
                maxWidth="xs"
                onClose={closeSignUpDialog}
            >
                <div style={{ marginTop: -19 }}>
                    <EasySignup onLogin={_handelLogin} onSignUp={handelSignUp} isLoginAfterSignUp={true} />
                </div>
            </CustomizedDialog>



            {/* ----User Details Dialog------ */}
            <CustomizedDialog
                title={"User details"}
                fullScreen={fullScreen}
                scroll="body"
                open={detailsDialog}
                TransitionComponent={Transition}
                fullWidth={true}
                classes={{ paper: classes.paperDialog }}
                maxWidth="xs"
                onClose={() => setDetailsDialog(false)}
                dialogContentStyle={{ height: '90%' }}
                dividers={false}
            >
                <div style={{ marginTop: -19, height: '90%' }}>
                    <UserDetails onUpdateDetails={handelUpdateDetails} />
                </div>
            </CustomizedDialog>


            <AlertDialog
                maxWidth="xs"
                open={alertMessage ? true : false}
                type={alertType}
                ContentText={alertMessage}
                ContentTextStyle={{ color: 'black', marginBottom: 0 }}
                title={null}
                titleStyle={{ paddingBottom: 0 }}
                cancelable={() => { }}
                action={[{ text: 'OK', onPress: () => onCloseAlert() }
                ]}
            />

            <div style={{ padding: 30 }} >
                <GridContainer className={classes.listGridContainer}>

                    <GridItem xs={12} sm={12} md={12} >
                        <div className={classes.backButtonContainer}>
                            <IconButton onClick={() => {
                                location.state
                                    ? props.history.push(location.state.backTo)
                                    : props.history.push("/products")
                            }

                            } style={{ color: "#fff" }}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="body1"
                                color="inherit"
                                noWrap
                                style={{ fontWeight: 'bold' }}
                            >
                                {"Product List"}
                            </Typography>
                        </div>

                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} >
                        <Collapse in={openPaymentStatus}>
                            <GridItem xs={12} sm={12} md={12} >
                                <div style={{ marginBottom: 20 }}>
                                    <Alert
                                        severity="error"
                                        elevation={12}
                                    // action={
                                    //     <Button color="secondary" size="sm" variant="contained">
                                    //         Try Again
                                    //      </Button>
                                    // }
                                    >
                                        <AlertTitle>Payment failed</AlertTitle>
                                        Unfortunately,we couldn't collect payment on your purchase.You can try again.
                                    </Alert>
                                </div>
                            </GridItem>
                        </Collapse>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={9} >
                        <div style={{ padding: 0 }} >
                            <div className='scrollNotification'>  {/* style={{ height: '90vh', overflow: "overlay", }} */}
                                <ItemCard
                                    data={cartItems}
                                    loading={loading}
                                    onRemove={handelRemoveItem}
                                />
                            </div>
                        </div>
                    </GridItem>
                    {payableAmount !== 0
                        && <GridItem xs={12} sm={12} md={3} >
                            <CheckoutCard
                                loading={loading}
                                payableAmount={payableAmount}
                                onCheckout={handelCheckout}
                                coupon={coupon}
                                setCoupon={setCoupon}
                                onApplyCoupon={handelApplyCoupon}
                                onRemoveCoupon={handelRemoveCoupon}
                                status={couponStatus}
                                appliedCoupon={couponCode}

                            />
                        </GridItem>
                    }
                </GridContainer>
            </div>
        </div>
    );
}

export default ShoppingCart;