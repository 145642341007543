import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// material-ui components
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

// custom style
import styles from "assets/jss/components/views/signinStyle";

//import axiosConfig from "util/axios";

// img
import QuestLogo from "assets/img/icons/QuestLogo.png";

// Views
import SigninContainer from 'components/Views/SignIn/SigninSpectrum/SigninComponent'


class SignIn extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      remember: true
    }
  }
  async componentWillMount() {
    if (sessionStorage.getItem('token')) {
      this.navigateTo()
    }
  }

    // async componentWillMount() {

    //   if (typeof window.Android !== 'undefined' || typeof onRemenberMeClicked !== 'undefined') {
    //     const search = window.location.search;
    //     const params = new URLSearchParams(search);
    //     const userName = params.get('userName');
    //     const password = params.get('password');
    //     const isAutologin = params.get('isAutologin')
    //     if (userName) {
    //       await this.setState({
    //         userName: userName,
    //         password: password,
    //         remember: true
    //       })
    //       if (isAutologin) {
    //         await this.setState({
    //           openProgress: true
    //         })
    //         await this.logIN()
    //       }
    //     }
    //   }

    //   if (localStorage.getItem('remember') === 'true') {
    //     this.setState({
    //       userName: localStorage.getItem('userName'),
    //       password: localStorage.getItem('password'),
    //       remember: true
    //     }, () => this.logIN())
    //     this.props.history.push('/intermediate')
    //     //this.navigateTo()
    //   } else if (sessionStorage.getItem('token')) {
    //     this.navigateTo()
    //   }
    // }

    // componentDidMount() {
    //   if (this.props.configs.tcUrl === "")
    //     this.props.initAppConfigs()
    // }


    // async logIN() {
    //   let showDashboard = false
    //   axiosConfig().post('/QuestUser/AuthenticateUser', {}, {
    //     auth: {
    //       username: this.state.userName,
    //       password: this.state.password
    //     }
    //   }).then(async response => {
    //     this.props.setAuthError(false)
    //     if (this.state.remember) {
    //       if (typeof window.Android !== 'undefined') {
    //         window.Android.onRemenberMeClicked(this.state.userName, this.state.password, true);
    //       }
    //       if (typeof onRemenberMeClicked !== 'undefined') {
    //         window.onRemenberMeClicked(this.state.userName, this.state.password, true);
    //       }
    //     }
    //     sessionStorage.setItem('token', response.data.token);
    //     await axiosConfig().get('/QuestUser/GetCurrentUser').then(response => {
    //       //this.props.initAppConfigs()
    //       this.props.setUserInfo(response.data)
    //       this.props.initUserQuestList()
    //       showDashboard = response.data.showDashboard
    //     }).catch(function (error) {
    //       this.props.fetchUserInfoFailed()
    //     });
    //     this.setState({ openProgress: false })
    //     this.navigateTo(showDashboard)
    //   }).catch((error) => {
    //     this.setState({ openProgress: false })
    //     if (error.response) {
    //       this.props.setAuthError(true)
    //       this.setState({
    //         open: true,
    //         errorMessage: error.response.data.error
    //       })
    //     }
    //   });
    // }

    // navigate To
    navigateTo(showDashboard = false) {
      if (showDashboard)
        return this.props.history.push('/products')
      return this.props.history.push('/home')
    }


    render() {
      const { classes } = this.props;

      return (
        <React.Fragment >
          <div className='signin-container background-signin'>
            <CssBaseline />
            <main className={classes.layout}>
              <img className={classes.imageStyle} src={QuestLogo} alt="..." />

              <Paper className={classes.signinPaperContainer}>
                <SigninContainer
                  textColor="white"
                  userInfo={{ ...this.state }}
                  onLogin={(test) => this.navigateTo(test)}
                  isBack={(this.props.platform === "IOS" || this.props.apptype === 'Spectrum') ? false : true}
                // onExplore={() => this.props.history.push('/products')}
                />
              </Paper>

            </main>
          </div >
        </React.Fragment >
      );
    }
  }

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  const mapStateToProps = state => {
    return {
      loading: state.appconfigs.loading,
      configs: state.appconfigs.configs,
      apptype: state.appconfigs.apptype,
      platform: state.platforms.platform,
    }
  }


  const mapDispatchToProps = dispatch => {
    return {
      //onInitAppConfigs: () => dispatch(questActions.initAppConfigs()),
      setAuthError: (auth) => dispatch(questActions.setAuthError(auth)),

      initAppConfigs: () => dispatch(questActions.initAppConfigs()),
      setUserInfo: (data) => dispatch(questActions.setUserInfo(data)),
      initUserQuestList: () => dispatch(questActions.initUserQuestList()),
      fetchUserInfoFailed: () => dispatch(questActions.fetchUserInfoFailed())
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SignIn)))

