import React, { useState, useEffect } from 'react';
//Redux
import { connect } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

//axios
import axiosConfig from "util/axios";

// core components
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'

//import style
import 'assets/css/loader.css';

//variables
import { NexoolURL } from "variables/ServerURL";

const NexoolView = (props) => {
    const [loading, setLoading] = useState(true)
    const [display, setDisplay] = useState('none')
    const [Token, setToken] = useState('')

    const hideSpinner = () => {
        setLoading(false);
        setDisplay('block')
    };

    useEffect(() => {
        const getToken = async () => {
            await axiosConfig().get('/QuestUser/GetNexoolToken', {
                params: {
                    UserId: props.userInfo.userId,
                    QuestId: props.quest.questId
                }
            }).then(async response => {
                setToken(response.data.token)
            })
        }
        getToken()
    }, []);




    return (
        <div>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={"Nexool"} back={"/home"} />

            {/*-----page-container----- */}
            <div className='page-container-5015'>
                {loading ? (
                    <div style={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <div className="loader">Loading...</div>
                    </div>

                ) : null}
                {Token ? <iframe
                    // title="HTMLViewer"
                    style={{
                        position: 'relative',
                        left: 0,
                        // bottom: 0,
                        right: 0,
                        width: '100%',
                        minHeight: '100vh',
                        border: "none",
                        display: display
                    }}
                    onLoad={hideSpinner}
                    src={`${NexoolURL}?token=${Token}`}
                />
                    : null
                }
            </div>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        userInfo: state.user.userinfo,
        quest: state.quest.current_quest,
    }
}


export default connect(mapStateToProps, null)(NexoolView);

