
//Messaging


export const SET_QUERY_DATA = "SET_QUERY_DATA"
export const FETCH_QUERY_LOADING = "FETCH_QUERY_LOADING"






