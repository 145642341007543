import React from 'react';
import { withRouter } from 'react-router-dom';
//import reducer 
import { useSelector, useDispatch } from 'react-redux'
import * as questActions from 'store/actions/index'
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
// @material-ui/core
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

//@material-ui/icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Common functions
import { clevertapEvents, facebookTracking } from "util/commonfunctions";

// styles
import useStyles from "assets/jss/components/views/taskStyle";

const GreenCheckIcon = withStyles((theme) => ({
    root: {
        color: green[400],
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }

    }
}))((props) => <CheckCircleIcon {...props} fontSize='small' />);

const ContentCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mission = useSelector(state => state.content.current_mission)
    const quest = useSelector(state => state.quest.current_quest)
    const operation = useSelector(state => state.content.current_operation)


    const onClickContentCard = () => {
        //clevertap
        const eventData = JSON.stringify({
            'QuestName': quest.questName,
            'MisssionName': mission.missionDisplayName,
            'OperationName': operation.operationDisplayName,
            'ContentType': props.content.contentTypeCode
        });
        clevertapEvents('ContentView', eventData);
        //facebook tracking
        const EventProperty = JSON.stringify({
            'fb_content_type': props.content.contentTypeCode,
            'fb_content_id': mission.missionName,
            'fb_currency': ''
        })
        facebookTracking('fb_mobile_content_view', EventProperty)
        dispatch(questActions.setCurrentContent(props.content))
        if (props.link) {
            props.history.push(props.link)
            // if (props.link === '/online-test') {
            //     let token = sessionStorage.getItem('token');
            //     let MLink = ' https://devadmin.questplus.in/online-assessment?tk=' + token + '&osaid=' + props.content.onlineStudentAssignmentId + '&type=web'
            //     props.history.push('/online-test', { testLink: MLink, testName: props.content.contentDisplayName })

            // } else {
                // props.history.push(props.link)
            // }
        }
        else
            props.setContentType(true)
    }

    return (<Card className={classes.contentCardStyle} onClick={onClickContentCard}>
        <CardActionArea className={classes.cardActionArea}>
            <CardMedia
                className={props.content.imageUrl ? classes.cardMediaImg : classes.cardMedia}
                component="img"
                image={props.content.imageUrl ? props.content.imageUrl : props.imgPath}
                title={props.content.contentDisplayName}
            />
        </CardActionArea>
        <CardContent className={classes.contentStyle}>
            <div style={{ display: 'flex', flexDirection: "column" }}>
                <>
                    <Typography variant="subtitle2" className={classes.contentCardTitle}>
                        {props.content.contentDisplayName}
                    </Typography>
                </>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>

                    <div className={classes.ptAndTmConatiner}>
                        {props.content.time ? <Typography variant="caption" style={{ paddingRight: 10, color: '#552E65' }} className={classes.textPointAndTime}>
                            {props.content.time}
                        </Typography>
                            : <div></div>
                        }

                        {props.content.points && <Typography variant="caption" style={{ color: '#552E65' }} className={classes.textPointAndTime}>
                            {props.content.points} Points
                        </Typography>
                        }

                    </div>
                    {props.content.progress.accessCount > 0 && <GreenCheckIcon />}
                </div>
            </div>

        </CardContent>
    </Card >)
}


export default withRouter(ContentCard);



