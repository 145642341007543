import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    divButtonsStyle: {
        display: 'flex',
        justifyContent: "space-between"
    },
    buttonBackNext: {
        padding: "8px 28px",
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px'
        },
    },

}));

export default useStyles;