import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    userVerificationStatus: {},
    OTPVerificationStatus: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_verification_STATUS:
            return {
                ...state,
                userVerificationStatus: action.status
            }
        case actionTypes.SET_OTP_verification_STATUS:
            return {
                ...state,
                OTPVerificationStatus: action.OTP_STATUS
            }

        default:
            return state;
    }

}


export default reducer