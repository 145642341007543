import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    bannerLists: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SET_PRODUCT_BANNER:
            return {
                ...state,
                bannerLists: action.payload,
            }

        default:
            return state;
    }

}


export default reducer