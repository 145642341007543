import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// import axios
import axiosConfig from "util/axios";
//validations
import { checkNull, checkEmail, checkMaxLength, checkMinLength } from 'util/validations'
//Common functions
import { clevertapEvents } from "util/commonfunctions";
//import Custom Components
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import CardHeader from "components/CustomComponents/Card/CardHeader.js";
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import Progress from "components/CustomComponents/Progress/Progress"
import Autocomplete from "components/CustomComponents/Autocomplete/Autocomplete"
// custom style
import useStyles from "assets/jss/components/views/userDetailsStyle";
//variables error
import { LENGTH_ERROR, MIN_LENGTH_ERROR } from 'variables/general'


const LoginDetails = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const classes = useStyles();

  const newuser = useSelector(state => state.newuser.registerUser)

  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const boards = useSelector(state => state.boardgrade.boards)
  const [board, setBoard] = React.useState(null);

  const gradeList = useSelector(state => state.boardgrade.grades)
  const [grades, setGrades] = React.useState([])
  const [grade, setGrade] = React.useState(null);

  const [state, setState] = useState({
    firstName: "",
    errorboard: "",
    errorgrade: "",
    errorfirstName: "",
  })

  const [userId, setUserId] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.userId)
    }
  }, [dispatch, userId])


  useEffect(() => {
    fetchBoardGrade()
  }, [dispatch])

  const fetchBoardGrade = async () => {
    setIsLoading(true)
    await dispatch(questActions.fetchBoardGrade()).then(() => {
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      console.log(error);
    })
  }


  const validateInputs = async () => {
    let validationObject = {};
    checkNull("firstName", state.firstName, "Name is required", validationObject);
    checkNull("board", board, "Please select board", validationObject);
    checkNull("grade", grade, "Please select grade", validationObject);

    checkMaxLength("firstName", state.firstName, 50, LENGTH_ERROR(50), validationObject);


    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorboard: "",
        errorgrade: "",
        errorfirstName: "",
        ...validationObject
      });
    } else {
      handleRegisterUser();
    }
  }

  // Update User info
  const handleRegisterUser = () => {
    const userInfo = {
      'userId': userId,
      'firstName': state.firstName,
      "boardId": board.boardId,
      "gradeId": grade.gradeId,
      "promoCode": newuser.promoCode,
      "UTMData": newuser.UTMData
    }

    setIsLoading(true);
    axiosConfig().post('/QuestUser/UpdateUser', userInfo).then(async response => {
      if (response.data.error) {
        setIsLoading(false);
        setAlertType("danger")
        setAlertMessage(response.data.error)
      } else {

        if ((response.data.questId !== undefined && response.data.questname !== undefined) && (response.data.questId !== "" && response.data.questname !== "")) {
          //clevertap
          const eventData = JSON.stringify({
            'QuestId': response.data.questId,
            'QuestName': response.data.questname
          });
          clevertapEvents('QuestSelected', eventData);
        }

        // await axiosConfig().get('/QuestUser/GetCurrentUser').then(response => {
        //   dispatch(questActions.setUserInfo(response.data))
        //   dispatch(questActions.initUserQuestList(props.isInitCurrentQuestAndContentList))
        //   if (response.data.userQuestList.length > 0) {
        //     dispatch(questActions.initUserQuestList(props.isInitCurrentQuestAndContentList)).then(() => {
        //       setIsLoading(false);
        //       history.push('/home')
        //     })
        //   } else if (response.data.showDashboard) {
        //     setIsLoading(false);
        //     history.push('/products')
        //   } else {
        //     setIsLoading(false);
        //     history.push('/home')
        //   }
        // }).catch(function (error) {
        //   setIsLoading(false);
        //   dispatch(questActions.fetchUserInfoFailed())
        // });
      }
    }).catch(function (error) {
      setAlertType("error")
      setAlertMessage(error.message)
    });
  }



  // Close Alert
  const onCloseAlert = () => {
    switch (alertType) {
      case "danger":
        setAlertMessage("")
        break;
      case "success":
        setAlertMessage("")
        props.handleNext()
        break;
      default:
        setAlertMessage("")
    }
  }

  /*-----------------------  on Chnage Board --------------------*/
  const onChnageBoard = (event, newValue) => {
    setBoard(newValue)
    if (newValue)
      setGrades(gradeList.filter(data => data.boardId === newValue.boardId))
    else
      setGrades([])
    setGrade(null)
  }



  return (
    <React.Fragment >
      <Progress open={isLoading} />
      <div className='signin-container background-signin'>
        <CssBaseline />

        <AlertDialog
          maxWidth="xs"
          open={alertMessage ? true : false}
          type={alertType}
          ContentText={alertMessage}
          ContentTextStyle={{ color: 'black', marginBottom: 0 }}
          title={null}
          titleStyle={{ paddingBottom: 0 }}
          cancelable={() => { }}
          action={[{ text: 'OK', onPress: () => onCloseAlert() }
          ]}
        />

        <main className={classes.UserDetailsLayout}>
          <Paper className={classes.paperContainer}>
            <div className={classes.UserDetailsPaper}>
              <GridContainer className={classes.stepContent}>
                <GridItem xs={12} sm={12} md={12}>
                  {/* <Paper > */}
                  <CardHeader
                    titleClassName={classes.titleHeader}
                    className={classes.cardHeader}
                    title={"Enter Details"}
                  />
                  {/* <CardBody> */}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextInput
                        id="FIRST_NAME"
                        autoFocus={true}
                        label="Name"
                        value={state.firstName}
                        errorText={state.errorfirstName}
                        onChange={(e) => setState({ ...state, firstName: e.target.value })}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Autocomplete
                        labelText="Select Board"
                        options={boards}
                        value={board}
                        onChange={onChnageBoard}
                        getOptionLabel={(option) => option.boardName ? option.boardName : ""}
                        errorText={state.errorboard}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Autocomplete
                        labelText="Select Grade"
                        options={grades}
                        value={grade}
                        onChange={(event, newValue) => { setGrade(newValue) }}
                        getOptionLabel={(option) => option.gradeName ? option.gradeName : ""}
                        errorText={state.errorgrade}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
                  <div className={classes.actionsContainer}>
                    <Button
                      round
                      fullWidth
                      color="primary"
                      disabled={isLoading}
                      onClick={validateInputs}
                      className={classes.button}
                    >
                      Continue
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </Paper>
        </main>
      </div>
    </React.Fragment>

  )
}

export default LoginDetails;