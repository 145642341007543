// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { blue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
       
    },
    listStyle: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    
    heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        // height: '3em',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
      },
}))

export default useStyles;