import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

// import material-ui/core
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

// import material-ui/icons
import Icon from '@material-ui/core/Icon';
import HomeIcon from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import MessageIcon from '@material-ui/icons/Message';
import Person from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CallIcon from '@material-ui/icons/Call';

//import style
import styles from "assets/jss/components/customcomponents/webHeaderStyle.js";

const useStyles = makeStyles(styles);


const MuiBottomNavigationAction = (props) => {
    const classes = useStyles();
    const { label, onClick, iconStyle, iconName, ...rest } = props
    return (
        <BottomNavigationAction
            {...rest}
            label={label}
            onClick={() => onClick()}
            icon={<Icon className={iconName} fontSize='small' classes={{ root: iconStyle }} />}
            className={classes.buttonNavigationAction}
            classes={{
                selected: classes.buttonNavigationsSelected,
                label: classes.actionLabel,
                root: classes.bottomNavigationActionRoot
            }}

        />
    )
}

const AdminNavbarLinks = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);




    return (

        <AppBar className={classNames(classes.appBar)} >
            <Toolbar className={classes.toolbar} variant="dense" classes={{ dense: classes.muiToolbarDense }}>
                <div className={classes.logoDiv}>
                    <img src={props.logo} className={classes.imageStyle} alt="..." />
                </div>
                {/* <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                >
                    {props.logoText}
                </Typography> */}



                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                    classes={{ root: classes.bottomNavigation }}
                >
                    <MuiBottomNavigationAction
                        label={"Home"}
                        onClick={() => props.history.push('/home')}
                        iconName={'icon-ic-account-balance-24px'}
                    />
                    <MuiBottomNavigationAction

                        label={"Progress"}
                        onClick={() => props.history.push('/home/dashboard')}
                        iconName={'icon-group-22'}
                        iconStyle={classes.muiIconRoot}
                    />
                    <MuiBottomNavigationAction

                        label={"Live classes"}
                        onClick={() => props.history.push('/home/connect')}
                        iconName={'icon-ic-live-tv-24px'}
                    />
                    <MuiBottomNavigationAction
                        label={"Profile"}
                        onClick={() => props.history.push('/home/profile')}
                        iconName={'icon-ic-camera-front-24px'} />
                </BottomNavigation>



                <div className={classes.rightIconContainer}>


                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                        <Typography variant="caption" className={classes.userNameText}>
                            {props.loading
                                ? <Skeleton animation="wave" width={70} height={25} />
                                : "Hi," + props.userInfo.firstName}
                        </Typography>

                        {props.loading
                            ? <Skeleton animation="wave" variant="circle" width={25} height={25} />
                            : <Avatar
                                src={props.userInfo.profilePic}
                                className={classes.smallAvatar}
                            />
                        }
                    </div>

                    <IconButton onClick={() => props.openLeaderBoard()} label="Leader Board" className={classes.iconbqbnseButton}>
                        <Icon fontSize="small" className='icon-bqbnse' classes={{ root: classes.iconRoot }} />
                    </IconButton>

                    <IconButton onClick={() => props.history.push('/home/notification')} label="Notification" className={classes.iconnNotificationsButton}>
                        <Icon fontSize="small" className='icon-ic-notifications-24px' classes={{ root: classes.iconRoot }} />
                    </IconButton>
                </div>

            </Toolbar>
        </AppBar>


    );
}

AdminNavbarLinks.propTypes = {
    classes: PropTypes.object
};
export default withRouter(AdminNavbarLinks);