import {
    whiteColor,
    primaryColor
} from "assets/jss/material-dashboard-react.js";


const drawerWidth = 240;
const styles = theme => ({
    root: {
        display: 'flex',
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: whiteColor,
        color: primaryColor[0],
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        //  justifyContent: 'space-between',
        ...theme.mixins.toolbar
    },
    muiToolbarDense: {
        minHeight: 50,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            minHeight: 45
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,

    },
    title: {
        fontWeight: 'bold',
        color: '#00B8AC',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        /* line-height: 16px; */
        /* max-height: 32px; */
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
        }
    },

    iconButton: {
        color: primaryColor[0],
        // padding: 5
    },



});

export default styles;
