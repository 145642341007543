import React from 'react';

//@material-ui/core
import CardActionArea from '@material-ui/core/CardActionArea';

// core components
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import Card from "components/CustomComponents/Card/Card.js";
import CardBody from "components/CustomComponents/Card/CardBody.js";
import CardIcon from "components/CustomComponents/Card/CardIcon.js";
import CardFooter from "components/CustomComponents/Card/CardFooter.js";
import Info from 'components/CustomComponents/Typography/Info';

// custom style
import useStyles from "./styles";

// Image
import DefaultImage from "assets/img/task/default.png";

const SubjectCard = (props) => {

    const classes = useStyles();

    const {
        data,
        onClick,
        ...rest
    } = props

    return (
        <GridContainer>
            {data.map((operation, index) => (
                <GridItem xs={4} sm={4} md={3} key={operation.operationId} className={classes.gridItemMissions}>
                    <Card className={classes.cardMissions} onClick={() => onClick(operation)} >
                        <CardActionArea>
                            <CardBody plain>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CardIcon >
                                            <img
                                                className={classes.imageStyle}
                                                src={operation.operationB64 ? operation.operationB64 : DefaultImage}
                                                alt="..."
                                            />
                                        </CardIcon>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12} >
                                        <div className={classes.stats} >
                                            <Info className={classes.infoText}>{operation.operationDisplayName}</Info>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </CardActionArea>
                    </Card>
                </GridItem>
            ))}

        </GridContainer >
    )
}


export default SubjectCard;