import * as actionTypes from '../actionTypes';
import axiosConfig from "util/axios";

//---Todays Online Classes---
export const setTodaysOnlineClasses = (MEETINGS_SCHEDULE) => {
    return {
        type: actionTypes.SET_TODAYS_ONLINE_CLASSES,
        schedule: MEETINGS_SCHEDULE
    }
}

export const fetchTodaysOnlineClasses = () => {
    return async (dispatch, getState) => {
        const UserId = getState().user.userinfo.userId
        await axiosConfig().get('/QuestSchedule/GetCurrentUserSchedules', {
            params: {
                UserId: UserId
            }
        }).then(response => {
            // console.log(response.data);
            dispatch(setTodaysOnlineClasses(response.data))
        }).catch(function (error) {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }
}

