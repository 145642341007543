import { makeStyles } from "@material-ui/core/styles";

import {
  successColor
} from "assets/jss/material-dashboard-react.js";


const Style = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing() * 5,
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 440,
      minHeight: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textAlign: 'center',
  },
  paper: {
    borderRadius: 20,
    // boxShadow: '0px 8px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
  },
  form_easysignup: {
    maxWidth: '328px',
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  actionsGridItem_easysignup: {
    margin: 0,
    padding:0
  },
  submit_easysignup: {
    marginTop: theme.spacing() * 1.5,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing() * 3,
  },
  close: {
    padding: theme.spacing() / 2,
  },
  stepperStyle: {
    // width: '100%',
    paddingRight: 0,
    paddingLeft: 0
  },
  actionsGridItem: {
    marginTop:10,
    justifyItems: 'flex-end',
    alignItems: 'flex-end',
    display: 'flex'
  },
  actionsContainer: {
    // padding: 15,
    width: '100%',
    display: 'flex',
    justifyContent: "space-between"
  },
  stepContent: {
    width: '100%',
    minHeight: 200
  },
  dialogTitle: {
    textAlign: "center",
    display:'flex',
    flexDirection:"column",
    alignItems:"center"
  },
  avatar: {
    // margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: 'auto',
    marginRight: 'auto',

  },
  titleHeader: {
    paddingLeft: 0,
    paddingRight: 0
  },
  titleHeader: {
    paddingLeft: 0,
    paddingRight: 0
  },
  successHeader: {
    paddingLeft: 0,
    paddingRight: 0,
    color: successColor[0]
  },
  stepperCardBody: {
    padding: '0.25rem 1rem'
  },
  dialogActions: {
    margin: 10,
    flexDirection: "column"
  },
  resendText: {
    textAlign: 'center',
    // width: -webkit-fill-available;
    justifyContent: 'center'
  },
  hyperText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.01071em',
    color: "#0000FF",
    cursor: 'pointer'
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  chip_getotp: {
    backgroundColor: successColor[0],
    letterSpacing: 1,
    marginBottom: 10
  },

}));

export default Style;