import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonAnalysisTable from "./CommonAnalysisTable";
import AssignMentChart from "./AssignMentChart";
import PageNavigation from "components/CustomComponents/Navbars/PageNavigation";
import TableLoader from "./TableLoader";

function convertSeconds(seconds) {
  if (
    seconds === 0 ||
    seconds === undefined ||
    seconds === "" ||
    seconds === null
  ) {
    return "0";
  }
  const minutes = Math.floor(seconds / 60); // Get the whole number of minutes
  const remainingSeconds = seconds % 60;
  return `${minutes} : ${remainingSeconds}`;
}

function AssessmentTestReport() {
  const [performanceData, setPerformanceData] = useState([]);
  const [overallPerformanceData, setOverallPerformanceData] = useState([]);
  const [chartData, setChartData] = useState({});

  const [performanceLoading, setPerformanceLoading] = useState(true);
  const [overallPerformanceLoading, setOverallPerformanceLoading] =
    useState(true);
  const [overallPerformanceError, setOverallPerformanceError] = useState(null);

  const [performanceError, setPerformanceError] = useState(null);

  const [timeManagement, setTimeManagement] = useState(true);
  const [timeManagementLoading, setTimeManagementLoading] = useState(true);
  const [timeManagementError, setTimeManagementError] = useState(null);

  useEffect(() => {
    fetchPerformanceData();
    fetchOverallPerformanceData();
    fetchTimeManagementData();
  }, []);

  const fetchTimeManagementData = async () => {
    setTimeManagementLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getTestTimeReport",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            groupId: "G1880",
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      setTimeManagement(timeManagementModifiedData(apiData));
      // setChartData(generateChartData(apiData));
    } catch (error) {
      setTimeManagementError("Failed to fetch performance data");
    } finally {
      setTimeManagementLoading(false);
    }
  };

  const timeManagementModifiedData = (apiData) => {
    return [
      {
        map: {
          testName: "Test Name",
          Your_Time: "By You (mm:ss)",
          Average_of_All_Student: "Avg of all students (mm:ss)",
          Average_of_Top_Ten_Percentile: "Avg of top 10 percentile (mm:ss)",
        },
        questions: apiData.map((q) => ({
          testName: q?.TestId
            ? `<a href='/report/${q.TestId}' style='color:#32b8b0'>${q.TestName} </a>`
            : q.TestName,
          Your_Time: convertSeconds(q.Your_Time),
          Average_of_All_Student: convertSeconds(q.Average_of_All_Student),
          Average_of_Top_Ten_Percentile: `${convertSeconds(
            q.Average_of_Top_Ten_Percentile
          )} / ${q.TotalTime} min`,
        })),
      },
    ];
  };

  const fetchPerformanceData = async () => {
    setPerformanceLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getOverallPerformanceAnalysisReport",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            groupId: "G1880",
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      setPerformanceData(processOverallPerformanceData(apiData));
      setChartData(generateChartData(apiData));
    } catch (error) {
      setPerformanceError("Failed to fetch performance data");
    } finally {
      setPerformanceLoading(false);
    }
  };

  const fetchOverallPerformanceData = async () => {
    setOverallPerformanceLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getPerformanceCategoryReport",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            groupId: "G1880",
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      console.log("ssssapiData", apiData);
      const map = new Map(
        [...apiData?.Data, ...apiData?.Overall].map((obj) => [obj.TestId, obj])
      );

      const mergedMainArray = Array.from(map.values());

      const userDatamap = new Map(
        [...apiData?.UserData, ...apiData?.OverallUserData].map((obj) => [
          obj.TestId,
          obj,
        ])
      );
      const mergedUserArray = Array.from(userDatamap.values());

      setOverallPerformanceData(
        processPerformanceData(mergedMainArray, mergedUserArray)
      );
    } catch (error) {
      setOverallPerformanceError("Failed to fetch overall performance data");
    } finally {
      setOverallPerformanceLoading(false);
    }
  };

  const generateChartData = (apiData) => {
    return {
      series: [
        {
          name: "Your Mark",
          data: apiData.map((q) => q.Your_Mark),
        },
        {
          name: "Avg of all student",
          data: apiData.map((q) => q.Average_of_All_Student),
        },
        {
          name: "Topper Marks",
          data: apiData.map((q) => q.Topper_Mark),
        },
        {
          name: "Avg of top 10 percentile",
          data: apiData.map((q) => q.Average_of_Top_Ten_Percentile),
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: apiData.map((q) => q.TestName),
        },
        yaxis: {
          title: {
            text: "Marks",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => ` ${val} Marks`,
          },
        },
      },
    };
  };

  const processPerformanceData = (apiData, userData) => {
    return [
      {
        map: {
          testName: "Test Name",
          above_85: ">85%",
          between_75_85: "75-85%",
          between_63_75: "63-75%",
          between_55_63: "55-63%",
          between_48_55: "48-55%",
          between_40_48: "40-48%",
          below_40: "<40%",
        },
        questions: apiData.map((q) => {
          const userDataObj = userData.find((obj) => obj.TestId === q.TestId);
          console.log("userDataObj", userDataObj);
          return {
            testName: q?.TestId
              ? `<a href='/report/${q.TestId}' style='color:#32b8b0'>${q.TestName} </a>`
              : "Overall",
            above_85: q.above_85,
            between_75_85:
              userDataObj?.between_75_85 > 0
                ? `<span style='color:green;font-size:16px;font-weight:bold'>${q.between_75_85}</span>`
                : q?.between_75_85,
            between_63_75:
              userDataObj?.between_63_75 > 0
                ? `<span style='color:green;font-size:16px;font-weight:bold'>${q.between_63_75}</span>`
                : q?.between_63_75,

            between_55_63:
              userDataObj?.between_55_63 > 0
                ? `<span style='color:green;font-size:16px;font-weight:bold'>${q.between_55_63}</span>`
                : q?.between_55_63,
            between_48_55:
              userDataObj?.between_48_55 > 0
                ? `<span style='color:green;font-size:16px;font-weight:bold'>${q.between_48_55}</span>`
                : q?.between_48_55,
            between_40_48:
              userDataObj?.between_40_48 > 0
                ? `<span style='color:green;font-size:16px;font-weight:bold'>${q.between_40_48}</span>`
                : q?.between_40_48,
            below_40:
              userDataObj?.below_40 > 0
                ? `<span style='color:green;font-size:16px;font-weight:bold'>${q.below_40}</span>`
                : q?.below_40,
          };
        }),
      },
    ];
  };

  const processOverallPerformanceData = (apiData) => {
    return [
      {
        map: {
          testName: "Test Name",
          Your_Mark: "Your Mark",
          Average_of_All_Student: "Avg of all student",
          Topper_Mark: "Topper Marks",
          Average_of_Top_Ten_Percentile: "Avg of top 10 percentile",
        },
        questions: apiData.map((q) => ({
          testName: q?.TestId
            ? `<a href='/report/${q.TestId}' style='color:#32b8b0'>${q.TestName} </a>`
            : q.TestName,
          Your_Mark: q.Your_Mark,
          Average_of_All_Student: q.Average_of_All_Student,
          Topper_Mark: q.Topper_Mark,
          Average_of_Top_Ten_Percentile: `${q.Average_of_Top_Ten_Percentile} / ${q.TotalMarks}`,
        })),
      },
    ];
  };
  // console.log("overallPerformanceData", overallPerformanceData);
  return (
    <>
      <PageNavigation logoText={"Dashboard"} back={"/assessment-dashboard"} />

      <div style={{ marginTop: "45px", marginBottom: "45px" }}>
        {/* Performance Categories Table */}
        {overallPerformanceLoading ? (
          <TableLoader
            heading={"Performance Categories"}
            data={"Loading performance data..."}
            loading={overallPerformanceLoading}
            error={overallPerformanceError}
          />
        ) : overallPerformanceError ? (
          <TableLoader
            heading={"Performance Categories"}
            data={overallPerformanceError}
            loading={overallPerformanceLoading}
            error={overallPerformanceError}
          />
        ) : (
          <CommonAnalysisTable
            data={overallPerformanceData}
            heading={"Performance Categories"}
          />
        )}

        {/* Performance Analysis Section */}
        <div
          style={{
            boxShadow: "0px 2px 22px 0px rgba(0, 0, 0, 0.12)",
            padding: "10px 0 10px 10px",
            marginBottom: "20px",
            backgroundColor: "#fff",
          }}
        >
          <h2 style={{ borderBottom: "1px solid #E9E9E9" }}>
            Overall Performance Analysis
          </h2>

          <div style={{ display: "flex" }}>
            {performanceLoading ? (
              <div
                className=""
                style={{
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "150px",
                  width: "100%",
                }}
              >
                <div className="circle-loader"></div>
                <div style={{ textAlign: "center" }}>
                  Loading Overall Performance Analysis ...
                </div>
              </div>
            ) : performanceError ? (
              <p>{performanceError}</p>
            ) : (
              <>
                <AssignMentChart
                  chartData={chartData}
                  style={{ width: "100%", backgroundColor: "#fff" }}
                />
                <CommonAnalysisTable
                  data={performanceData}
                  heading={""}
                  style={{
                    boxShadow: "none",
                    border: "none",
                    marginBottom: "0px",
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div>
          {timeManagementLoading ? (
            <TableLoader
              heading={"Time Management"}
              data={"Loading Time Management data..."}
              loading={timeManagementLoading}
              error={timeManagementError}
            />
          ) : timeManagementError ? (
            <TableLoader
              heading={"Time Management"}
              data={"Failed to fetch Time Managemenrt  data"}
              loading={timeManagementLoading}
              error={timeManagementError}
            />
          ) : (
            <CommonAnalysisTable
              data={timeManagement}
              heading={"Time Management"}
              style={{ width: "50%", margin: "0" }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AssessmentTestReport;
