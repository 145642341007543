import React from 'react'
import moment from 'moment'
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import Typography from '@material-ui/core/Typography';

// components
import Button from 'components/CustomComponents/CustomButtons/Button'
// @material-ui/icons/
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import useStyles from "assets/jss/components/customcomponents/monthYearSelectorStyle";

const currentDate = new Date()

const MonthYearSelector = (props) => {
    const classes = useStyles();
    const {
        value,
        onChange,
        ...rest
    } = props;

    const handleNext = () => {
        onChange(moment(value).add(1, 'month'))
    }

    const handleBack = () => {
        onChange(moment(value).subtract(1, 'month'))
    }

    const isLastStep = () => {
        return (moment(value).year() === moment(currentDate).year() && moment(value).month() === moment(currentDate).month())
    }

    return (<div className={classes.divButtonsStyle}>
        <Button simple={true} color="primary" className={classes.buttonBackNext} onClick={handleBack} color="white">
            {/* {disabled={activeStep === 0} } */}
            <ArrowBackIosIcon />
        </Button>
        <Typography style={{ color: "#fff" }}>{moment(value).format('MMMM YYYY')}</Typography>
        <Button simple={true} color="primary" className={classes.buttonBackNext} onClick={handleNext} disabled={isLastStep()} color="white">
            <ArrowForwardIosIcon />
        </Button>
    </div>)
}

MonthYearSelector.defaultProps = {
    value: currentDate
}

MonthYearSelector.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
};

export default MonthYearSelector;