import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // maxWidth: 400,
        flexGrow: 1,
        backgroundColor: '#fff !important'
    },
    mobileStepper: {
        flexGrow: 1,
        justifyContent: "center !important",
        background: 'transparent !important'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: '100%',
        display: 'block',
        overflow: 'hidden',
        width: '100%',
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    swipeableViewsContainer: {
        paddingTop: 20
    }, buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        // textAlign: 'center',
        alignItems: 'center',
        padding: '0 15px',
    },
    topicsTitle: {
        width: '85%',
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            '-webkit-line-clamp': '2',
        },
    },
    accordionDiv: {
        overflowY: 'overlay',
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
    },
    accordionClass: {
        // backgroundColor: '#F2F8FF',
        margin: '10px 10px !important',
        borderRadius: '5px !important',
        "&:before": {
            height: "0px !important"
        }
    },
    accordionSummaryClass: {
        padding: '0px 16px 0px 10px'
    },

    accordionSummaryRoot: {
        minHeight: 'unset !important',
        height:50,
        [theme.breakpoints.down('sm')]: {
            height:30
        },
       
    },

    accordionDetails:{
        padding: '0px 5px 1px',
        display: 'block',
        marginTop:-8
    },

    heading: {
        height: 'auto',
        lineHeight: 1.1,
        fontWeight: 'bold',
        color: '#000000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',   
            // lineHeight: '1.66',
            letterSpacing: '0.03333em'
        }

    },
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },
    joinMasterClassCard:{
        boxShadow: '0px 2px 10px 0px  rgba(0,0,0,0.15)',
        borderRadius: '5px',
        padding: '50px',
        // width: '400px',
        textAlign: 'center',
        cursor: 'pointer',
        position:'relative',
    },
    joinNow:{
        border:'none',
        background: 'linear-gradient(264.15deg, #4F90EE 0%, #0945BF 100%)',
        padding:'12px 28px 12px 28px',
        cursor:'pointer',
        borderRadius:'6px',
        color:'#fff',
        fontSize:'16px',
    },
    joinMasterClassText:{
        fontSize:'24px',
        fontWeight:'500',
        color:'#425466'
    },
    logoContainer:{
        position:'absolute',
        top:'10px',
        left:'10px',
    },
    mainContainer:{
        padding:'10px',
    },
    blinkingRedDot: {
        width: '10px',
        height: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        animation: '$blink 1s infinite'
    },
    '@keyframes blink': {
        '0%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        }
    },
    liveclass:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'5px',
        backgroundColor:'#fff',
        borderRadius:'4px',
        padding:'2px 10px',
        cursor:'pointer',
        border:'1px solid red',
        fontWeight:'bold',
    },
    instructionContainer:{
        marginTop:'40px',
        padding:'5px',
        border:'1px solid #ccc',
        borderRadius:'5px',

        '& h3':{
            fontWeight:'bold',
            color:'#000',
            marginBottom:'10px',
            padding:'5px',
            borderBottom:'1px solid #ccc',

        }, 
        '& ul':{
            padding:'5px',
            margin:'0',
            listStyle:'none',
            textAlign:'left',
        },
        '& li':{
            padding:'5px',
            margin:'0',
            listStyle:'none',
            textAlign:'left',
        },

    }




}));

export default useStyles;