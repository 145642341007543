import React, { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
//@material-ui/core
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
//material-ui/icons/
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import Button from "components/CustomComponents/CustomButtons/Button.js";
//Lottie
import Lottie from 'react-lottie';
import ShoppingCartLottie from 'assets/lotties/shopping-cart';
import DefaultImage from "assets/img/task/default.png";
// custom style
import useStyles from "assets/jss/components/views/cartStyle";

const CartLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: ShoppingCartLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


const SkeletonCard = (props) => {
    const classes = useStyles();
    return (<React.Fragment>
        <Typography variant="h5" component="h2" style={{ marginBottom: 10 }}>
            <Skeleton animation="wave" />
        </Typography>
        {['IT0011', 'ITM0101', 'IPS0111', 'ITP1111'].map((v, i) =>
            <Card style={{ marginBottom: 30, borderRadius: 10, }} key={v}>
                <CardContent style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton animation="wave" variant="rect" width={128} height={70} />
                    <div style={{ width: '100%', padding: 10 }}>
                        <Skeleton animation="wave" variant="rect" />
                        <div style={{ height: 5 }}></div>
                        <Skeleton animation="wave" variant="rect" height={30} />
                    </div>
                </CardContent>
            </Card>
        )}
    </React.Fragment>
    )
}



const ItemCard = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const location = useLocation();
    const {
        data,
        onRemove,
        loading,
        ...rest
    } = props;

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }

    const isSamePrice = (Math.floor(props.data.netPrice) === Math.floor(props.data.price))

    return (

        <React.Fragment >
            {data.length ?
                <React.Fragment>
                    <Typography className={classes.lengthTitle} variant="body1" component="h2" gutterBottom>
                        {data.length} Product in Cart
                    </Typography>

                    {
                        data.map((cartitme, idex) => (
                            <div className={classes.root} key={"CHECKOUT" + cartitme.productId}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={9} md={9} container spacing={1}>
                                            <Grid item xs={5} sm={2} md={2} >
                                                <ButtonBase className={classes.image}>
                                                    <img className={classes.img} alt="complex" src={cartitme.productIcon ? cartitme.productIcon : DefaultImage} />
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item xs={7} sm={10} md={10}>
                                                <Typography gutterBottom variant="subtitle1" className={classes.nameTitle} >
                                                    {cartitme.productName}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={3} md={3} container>
                                            <Grid item xs>
                                                <Typography variant="body2" style={{ cursor: 'pointer' }} component='h2' onClick={() => onRemove(cartitme)}>
                                                    Remove
                                                </Typography>
                                            </Grid>
                                            <Grid item xs >
                                                <Typography variant="subtitle2" className={classes.priceTitle}>
                                                    <samp style={{ fontWeight: "normal" }}>₹</samp>{cartitme.netPrice}{ /*  Math.floor() */}
                                                    <LocalOfferIcon fontSize="small" />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        ))
                    }
                </React.Fragment>

                : <div style={{ justifyContent: "center", display: 'flex' }}>
                    <CardContent style={{ justifyContent: "center", display: 'flex', flexDirection: 'column', textAlign: 'center', color: "#fff" }}>
                        <Lottie options={CartLottieOptions} height={200} width={200} />
                        <Typography variant="h6" style={{ color: "inherit" }} gutterBottom={true}>
                            {"Your cart is empty. Keep shopping to find a Quest!"}
                        </Typography>
                        <Button
                            className={classes.buttonKeepShop}
                            color="primary"
                            size='normal'
                            onClick={() => {
                                location.state
                                    ? history.push(location.state.backTo)
                                    : history.push("/products")
                            }} >Keep shopping</Button>
                    </CardContent>
                </div>
            }
        </React.Fragment>
    );
}

export default ItemCard;