
import * as actionTypes from './actionTypes';

export const setPlatform = (platform) => {
    return {
        type: actionTypes.SET_PLATFORM,
        platform: platform
    }
}

