import React, { useState } from "react";

//Redux
import { useDispatch, useSelector } from 'react-redux'

//@material-ui/core
import { useTheme } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Zoom from '@material-ui/core/Zoom';

//@material-ui/icons
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';

//Custom Components
import MessagesCard from "components/Views/QueryBox/components/MessageComponents/MessagesCard";
import MessageDeatilsCard from "components/Views/QueryBox/components/MessageComponents/MessageDeatilsCard";

//Views
import Compose from '../../components/Compose'

//Style
import useStyles from './styles.js'

const Inbox = (props) => {
    const { loading } = props

    const inboxMessages = useSelector(state => state.messages.inbox_messages)

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [openMessageDeatils, setOpenMessageDeatils] = useState(false);

    const [currentQuery, setCurrentQuery] = useState({});

    // Dialog Close
    const handleClose = () => {
        setOpen(false);
    };

    // Dialog Open
    const handleOpen = () => {
        setOpen(true);
    };

    // Dialog Close Message Deatils
    const handleCloseMessageDeatils = () => {
        setOpenMessageDeatils(false);

    };

    // Dialog Open Message Deatils
    const handleOpenMessageDeatils = (CurrentQuery) => {
        setCurrentQuery(CurrentQuery)
        setOpenMessageDeatils(true);
    };


    //Handle Reply 
    const handleReply = () => {
        handleCloseMessageDeatils()
        handleOpen();
    }

    return (
        <div className={classes.mainContainer}>
            {/* Dialog */}
            <Dialog
                open={open}
                maxWidth={"md"}
                fullWidth={true}
                TransitionComponent={Zoom}
                fullScreen={fullScreen}>
                <Compose onClose={handleClose} />
            </Dialog>

            <Dialog
                TransitionComponent={Zoom}
                open={openMessageDeatils}
                fullWidth={true}
                maxWidth={"md"}
                fullScreen={fullScreen}>

                <MessageDeatilsCard
                    currentQuery={currentQuery}
                    onClose={handleCloseMessageDeatils}
                    onReply={handleReply}
                />
            </Dialog>


            <MessagesCard
                data={inboxMessages}
                onClickRow={handleOpenMessageDeatils}
                loading={loading}
                noDataMessage={"No data available"}
            />

            <Fab
                size="medium"
                color="secondary"
                aria-label="add"
                className={classes.fab}
                onClick={handleOpen}
            >
                <AddIcon className={classes.addIcon} />

            </Fab>
        </div>
    )
}

export default Inbox;