import React, { useEffect, useState } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux'
import * as questActions from 'store/actions/index'

//@material-ui/core
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//@material-ui/icons/
import DeleteIcon from '@material-ui/icons/Cancel';
//Custom Components
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
// custom style
import useStyles from "assets/jss/components/views/cartStyle";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SkeletonCard = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.checkoutCardContainer}>
            <Card className={classes.checkoutCard}>
                <CardContent>
                    <Typography className={classes.title} gutterBottom>
                        <Skeleton animation="wave" />
                    </Typography>
                    <Typography variant="h4" component="h2">
                        <Skeleton animation="wave" />
                    </Typography>
                    <Skeleton animation="wave" variant="rect" height={40} />
                </CardContent>

                <Divider style={{ marginLeft: 10, marginRight: 10 }}></Divider>

                <CardContent>
                    <Skeleton animation="wave" variant="rect" />
                    <div style={{ height: 5 }}></div>
                    <Skeleton animation="wave" variant="rect" height={30} />
                </CardContent>

            </Card>
        </div>
    )
}

const PriceGrid = (props) => {
    const { label, amount, labelStyle, signs } = props;
    const classes = useStyles();

    return (
        <Grid container spacing={2} >
            <Grid item xs={7}>
                <Typography className={classes.title} style={labelStyle} variant='subtitle2'>
                    {label} :
                </Typography>
            </Grid>
            <Grid item xs={5} style={{ textAlign: 'end' }}>
                <Typography style={{ fontWeight: "bold" }} variant='subtitle2'>
                    {signs} <span style={{ fontWeight: "normal" }}>₹ </span>{amount}
                </Typography>
            </Grid>
        </Grid>
    )
}


const ShoppingCart = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
        onCheckout,
        payableAmount,
        loading,
        coupon,
        setCoupon,
        onApplyCoupon,
        onRemoveCoupon,
        status,
        appliedCoupon,
        ...rest
    } = props;

    const [alertType, setAlertType] = React.useState("");
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [discountAmt, SetDiscountAmt] = useState(0)
    const [totalAmt, SetTotalAmt] = useState(0)


    useEffect(() => {
        setAlertType(status.isSuccess ? 'success' : 'error')
        setSnackbarMessage((status.message))
        SetDiscountAmt(0)
        SetTotalAmt(payableAmount)

        if (status.isSuccess) {
            SetDiscountAmt(status.extraValue)
            SetTotalAmt(payableAmount - status.extraValue)
            setCoupon("")
        }
    }, [status, payableAmount])

    if (loading) {
        return (<SkeletonCard></SkeletonCard>)
    }

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    // Close Snackbar
    const handeCloseSnackbar = () => {
        if (!status.isSuccess) {
            dispatch(questActions.setStoreCouponStatus({}))
        }
        setSnackbarMessage("")
    }

    return (
        <div className={classes.checkoutCardContainer}>

            <Snackbar open={snackbarMessage ? true : false} autoHideDuration={3000} onClose={handeCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handeCloseSnackbar} severity={alertType}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Grid item xs={12} sm={6} md={12} container >
                <Card className={classes.checkoutCard}>

                    <CardContent>
                        {discountAmt > 0 ?
                            <React.Fragment>
                                <PriceGrid label={"Total"} amount={payableAmount} />
                                <PriceGrid label={"Coupon Discount"} amount={discountAmt} signs={"-"} />
                            </React.Fragment>
                            : null
                        }

                        <PriceGrid label={" Total Amount"} labelStyle={{ fontWeight: "bold" }} amount={totalAmt} />
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Button fullWidth color="rose" onClick={props.onCheckout}>Checkout</Button>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <Divider style={{ marginLeft: 10, marginRight: 10 }}></Divider>

                    <CardContent>
                        <Grid item xs={12} sm={12} md={12} container >
                            <GridItem xs={9} sm={9} md={9} className={classes.applyCouponTextContainer}>
                                <TextInput
                                    variant="outlined"
                                    inputProps={{ className: classes.applyCouponTextInput,maxLength: 6 }}
                                    FormHelperTextProps={{ className: classes.applyCouponInputLabel }}
                                    // autoFocus={true}
                                    placeholder="Enter Coupon"
                                    value={coupon}
                                    //   errorText={this.state.erroWAREHOUSE_NAME}
                                    onChange={(e) => setCoupon(e.target.value)}
                                    disabled={appliedCoupon?true:false}
                                />
                            </GridItem>
                            <GridItem item xs={3} sm={3} md={3} className={classes.applyCouponButtonContainer}>
                                {/* <div className={classes.applyCouponButtonContainer}> */}
                                <Button  disabled={appliedCoupon?true:false} variant="contained" size='sm' fullWidth color="success" onClick={onApplyCoupon}>
                                    Apply
                                   </Button>
                                {/* </div> */}
                            </GridItem>

                            {appliedCoupon ?
                                <GridItem item xs={12} sm={12} md={12} className={classes.applyCouponButtonContainer}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton size="small" onClick={onRemoveCoupon}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <Typography variant='subtitle2'>
                                            <span style={{ fontWeight: "bold" }}>{appliedCoupon}</span> coupon is applied
                                 </Typography>
                                    </div>
                                </GridItem>
                                : null}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
}

export default ShoppingCart;