import React from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// style
import {
  grayColor,
  infoColor,
  dangerColor,
  successColor,
  orangeColor
} from "assets/jss/material-dashboard-react.js";


// const StyledProgress = withStyles({
//   colorPrimary: {
//     backgroundColor: grayColor[0]
//   },
//   bar: {
//     backgroundColor: infoColor[0],
//   },

// })(LinearProgress);

// Inspired by the former Facebook spinners.
const useStyles = makeStyles((theme) => ({
  colorPrimary25: {
    backgroundColor: dangerColor[0],
  },
  colorPrimary50: {
    backgroundColor: orangeColor[0],
  },
  colorPrimary100: {
    backgroundColor: successColor[0],
  },
  colorPrimary: {
    backgroundColor: grayColor[2]
  },

}));


const LinearProgressWithLabel = (props) => {
  const {
    textcolor,
    value,
    alignItems,
    lableBoxStyle,
    progressBarWidth,
    lableStyle,
    ...rest } = props;

  const classes = useStyles();

  const progressClass = classNames({
    [classes[`colorPrimary${value <= 25 ? '25' : (value > 25 && value <= 50) ? '50' : '100'}`]]: true,
  });

  return (
    <Box display="flex" alignItems={alignItems} width='100%'>
      <Box width={progressBarWidth} mr={1}>
        <LinearProgress
          variant="determinate"
          classes={{
            bar: progressClass,
            colorPrimary: classes.colorPrimary
          }}
          value={value}
          {...rest} />
      </Box>
      <Box minWidth={35} style={{ ...lableBoxStyle }}>
        <Typography variant="caption" style={{ color: textcolor, fontWeight: 600, ...lableStyle }}>{`${Math.round(
          value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.defaultProps = {
  textcolor: infoColor[0],
  alignItems: "center",
  progressBarWidth: "95%"
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  textcolor: PropTypes.string,
  alignItems: PropTypes.string,
  lableBoxStyle: PropTypes.object,
  lableStyle: PropTypes.object,
  progressBarWidth: PropTypes.string,
};

export default LinearProgressWithLabel;



