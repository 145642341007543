import React, { useState } from 'react';
import PropTypes from "prop-types";
//Redux
import { useSelector } from 'react-redux'

// import material component
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';

//import Custom Components
import FLName from './FLName'
import LoginDetails from './LoginDetails'
import MobileNumber from './MobileNumber'
import OtherDetails from './OtherDetails'
import CouponCode from './CouponCode'

import { SwitchTransition, CSSTransition } from "react-transition-group";

// custom style
import useStyles from "assets/jss/components/views/signupStyle";

// img
import QuestLogo from "assets/img/icons/QuestLogo.png";

const modes = ["out-in", "in-out"];

const Signup = (props) => {
  const classes = useStyles();
  const appConfigs = useSelector(state => state.appconfigs.configs)

  const [activeStep, setActiveStep] = useState(0);
  const steps = appConfigs.showPromoScreen ? ['1', '2', '3'] : ['1', '2'];
  const [mode, setMode] = useState("out-in");
  const [state, setState] = useState(true);
  const [loading, setLoading] = useState(false)

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      props.onSignUp()
      //props.history.push('/')
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setState(state => !state)
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.onClose()
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setState(state => !state)
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <MobileNumber handleNext={handleNext} handleBack={handleBack} handleLoading={setLoading} activeStep={activeStep} steps={steps} />
      case 1:
        return <LoginDetails
          handleNext={handleNext}
          handleBack={handleBack}
          handleLoading={setLoading}
          activeStep={activeStep}
          steps={steps} 
          isLogin={props.isLoginAfterSignUp}
          />
      case 2:
        return <CouponCode handleNext={handleNext} handleBack={handleBack} handleLoading={setLoading} activeStep={activeStep} steps={steps} />
      default:
        return 'Unknown step';
    }
  }


  return (

    <React.Fragment >
      <div className='signin-container background-signin'>
        <CssBaseline />
        <main className={classes.layout}>
          <img className={classes.imageStyle} src={QuestLogo} alt="..." />

          {loading ? < LinearProgress color="primary" style={{ margin: '0px 20px 0px 20px' }} /> : null}
          <Paper className={classes.paper}>

            <Stepper activeStep={activeStep} className={classes.stepperStyle}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>

            <SwitchTransition mode={mode}>
              <CSSTransition
                key={activeStep}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
              >
                {getStepContent(activeStep)}

              </CSSTransition>
            </SwitchTransition>
          </Paper>

        </main>
      </div >
    </React.Fragment >

  );
}


Signup.defaultProps = {
  onSignUp: () => { },
  isLoginAfterSignUp: false,
}

Signup.propTypes = {
  onSignUp: PropTypes.func,
  isLoginAfterSignUp: PropTypes.bool,
}

export default Signup;
