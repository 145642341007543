import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';


// imoprt custom-component
import Navbar from '../Navbars/Navbar'


const styles = theme => ({
    root: {
        display: 'flex',
    },
});


class Drawer extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <div className={classes.root}>
                    <Navbar {...this.props}/>
                    {/* <Sidebar {...this.props}></Sidebar> */}
                </div>
            </React.Fragment>
        );
    }
}

Drawer.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(Drawer);