import React, { useState, useEffect } from "react";
import axios from "axios";
import QuestionWiseAnalysis from "./QuestionWiseAnalysis";
import CommonAnalysisTable from "./CommonAnalysisTable";
import PageNavigation from "components/CustomComponents/Navbars/PageNavigation";
import { useParams } from "react-router-dom";
import TableLoader from "./TableLoader";

function TestReport() {
  const { id } = useParams();

  const [bloomData, setBloomData] = useState([]);
  const [bloomDataLoading, setBloomDataLoading] = useState(true);
  const [bloomDataError, setBloomDataError] = useState(null);

  const [levelOfEffort, setLevelOfEffort] = useState([]);
  const [levelOfEffortLoading, setLevelOfEffortLoading] = useState(true);
  const [levelOfEffortError, setLevelOfEffortError] = useState(null);

  const [dokData, setdokData] = useState([]);
  const [dokDataLoading, setdokDataLoading] = useState(true);
  const [dokDataError, setdokDataError] = useState(null);

  useEffect(() => {
    fetchTBloomData(id);
    fetchEffortLevelData(id);
    fetchDokLevelData(id);
  }, [id]);

  const fetchTBloomData = async (testId) => {
    setBloomDataLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getTestBloomLevelAnalysis",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            testId: testId,
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      console.log("apiData", apiData);
      setBloomData(bloomModifiedData(apiData));
      // setChartData(generateChartData(apiData));
    } catch (error) {
      setBloomDataError("Failed to fetch Bloom data");
    } finally {
      setBloomDataLoading(false);
    }
  };

  const fetchEffortLevelData = async (testId) => {
    setLevelOfEffortLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getTestLOELevelAnalysis",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            testId: testId,
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      console.log("apiData", apiData);
      setLevelOfEffort(levelOdEffortModifiedData(apiData));
      // setChartData(generateChartData(apiData));
    } catch (error) {
      setLevelOfEffortError("Failed to fetch Level Of Effort data");
    } finally {
      setLevelOfEffortLoading(false);
    }
  };

  const fetchDokLevelData = async (testId) => {
    setdokDataLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getTestDOKLevelAnalysis",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            testId: testId,
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      console.log("apiData", apiData);
      setdokData(dokModifiedData(apiData));
      // setChartData(generateChartData(apiData));
    } catch (error) {
      setdokDataError("Failed to fetch Dok Level Of Effort data");
    } finally {
      setdokDataLoading(false);
    }
  };
  let bloomResMapdata = {
    Average_Of_All_Students: "Average of all students",
    Average_Of_Top_Ten_Percentile: "Average of top 10 percentile",
    Your_Score: "Your Score",
  };
  const bloomModifiedData = (apiData) => {
    if (!apiData || typeof apiData !== "object") {
      throw new Error("Invalid input: apiData must be an object");
    }

    const bloomResult = Object.entries(apiData)
      .map(([key, value]) => {
        if (!Array.isArray(value) || value.length === 0) {
          return null;
        }

        const data = value[0];
        return {
          testName: bloomResMapdata[key] || key,
          recall: data.Recall ?? 0,
          understand: data.Understand ?? 0,
          analysis: data.Analysis ?? 0,
          create: data.Create ?? 0,
          application: data.Application ?? 0,
          evaluate: data.Evaluate ?? 0,
        };
      })
      .filter((item) => item !== null);

    return [
      {
        map: {
          testName: "Bloom Name",
          recall: "Recall",
          understand: "Understand",
          analysis: "Analysis",
          create: "Create",
          application: "Application",
          evaluate: "Evaluate",
        },
        questions: bloomResult,
      },
    ];
  };

  const levelOdEffortModifiedData = (apiData) => {
    if (!apiData || typeof apiData !== "object") {
      throw new Error("Invalid input: apiData must be an object");
    }

    const bloomResult = Object.entries(apiData)
      .map(([key, value]) => {
        if (!Array.isArray(value) || value.length === 0) {
          return null;
        }

        const data = value[0];
        return {
          testName: bloomResMapdata[key] || key,
          low: data.Low ?? 0,
          moderate: data.Moderate ?? 0,
          high: data.High ?? 0,
        };
      })
      .filter((item) => item !== null);

    return [
      {
        map: {
          testName: "Effort Level",
          low: "Low",
          moderate: "Moderate",
          high: "High",
        },
        questions: bloomResult,
      },
    ];
  };

  const dokModifiedData = (apiData) => {
    if (!apiData || typeof apiData !== "object") {
      throw new Error("Invalid input: apiData must be an object");
    }

    const dokResult = Object.entries(apiData)
      .map(([key, value]) => {
        if (!Array.isArray(value) || value.length === 0) {
          return null;
        }

        const data = value[0];
        return {
          testName: bloomResMapdata[key] || key,
          Application: data.Application ?? 0,
          Extended_Thinking: data.Extended_Thinking ?? 0,
          Recall: data.Recall ?? 0,
          Strategic_Thinking: data.Strategic_Thinking ?? 0,
        };
      })
      .filter((item) => item !== null);

    return [
      {
        map: {
          testName: "Difficult Level",
          Application: "Application",
          Extended_Thinking: "Extended Thinking",
          Recall: "Recall",
          Strategic_Thinking: "Strategic Thinking",
        },
        questions: dokResult,
      },
    ];
  };

  return (
    <>
      <PageNavigation logoText={"Report"} back={"/assessment-report"} />
      <div style={{ marginTop: "45px", marginBottom: "45px" }}>
        {bloomDataLoading ? (
          <TableLoader
            heading={"Bloom Level wise Analysis"}
            data={"Loading Bloom Level wise Analysis data..."}
            loading={bloomDataLoading}
            error={bloomDataError}
          />
        ) : bloomDataError ? (
          <TableLoader
            heading={"Bloom Level wise Analysis"}
            data={"Failed to fetch Time Managemenrt  data"}
            loading={bloomDataLoading}
            error={bloomDataError}
          />
        ) : (
          <CommonAnalysisTable
            data={bloomData}
            heading={"Bloom Level wise Analysis"}
          />
        )}

        <div style={{ display: "flex", gap: "50px" }}>
          {dokDataLoading ? (
            <TableLoader
              heading={"Depth of Knowledge (DOK) wise Analysis"}
              data={"Loading Depth of Knowledge (DOK) wise Analysis data..."}
              loading={dokDataLoading}
              error={dokDataError}
            />
          ) : dokDataError ? (
            <TableLoader
              heading={"Depth of Knowledge (DOK) wise Analysis"}
              data={
                "Failed to fetch Depth of Knowledge (DOK) wise Analysis  data"
              }
              loading={dokDataLoading}
              error={dokDataError}
            />
          ) : (
            <CommonAnalysisTable
              data={dokData}
              heading={"Depth of Knowledge (DOK) wise Analysis"}
            />
          )}

          {levelOfEffortLoading ? (
            <TableLoader
              heading={"Effort Level wise Analysis"}
              data={"Loading Effort Level wise Analysis data..."}
              loading={levelOfEffortLoading}
              error={levelOfEffortError}
            />
          ) : levelOfEffortError ? (
            <TableLoader
              heading={"Effort Level wise Analysis"}
              data={"Failed to fetch Time Managemenrt  data"}
              loading={levelOfEffortLoading}
              error={levelOfEffortError}
            />
          ) : (
            <CommonAnalysisTable
              data={levelOfEffort}
              heading={"Effort Level wise Analysis"}
            />
          )}
        </div>

        <QuestionWiseAnalysis testId={id} />
      </div>
    </>
  );
}

export default TestReport;
