import React, { useState, useEffect } from "react";

//Redux
import { useDispatch } from 'react-redux'
//import reducer 
import * as actions from "components/Views/Connect/store/actions/index"

//@material-ui/icons
import InboxIcon from '@material-ui/icons/Inbox';
import ForumIcon from '@material-ui/icons/Forum';

// custom components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"

// Views
import Todays from './Todays'



// Styles
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    listGridContainer: {
        display: 'flex',
        justifyContent: "center"
    },

}));


const Container = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState({ name: "Today's", icon: InboxIcon })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        let dispatchAction = actions.fetchTodaysOnlineClasses()
        dispatch(dispatchAction).then(() => {
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            console.log(error);
        });
    }, [selectedTab])


    return (
      
            <div className='page-container background-home' >
                <GridContainer className={classes.listGridContainer}>
                    <GridItem xs={12} sm={12} md={6} >
                        <div style={{ padding:'0px 10px'}} >
                            <Todays
                                loading={loading}
                                onChangeMonthYear={(value) => { }}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        
    )
}

export default Container