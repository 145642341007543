import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    productcategories: {
        productCategory: [
            {
                categoryName: {},
                productList: []
            }
        ]
    },
    productdetails: {}

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_QUESTPRODUCTS:
            return {
                ...state,
                productcategories: { ...action.productCategory }
            }
        case actionTypes.SET_PRODUCT_DETAILS:
            return {
                ...state,
                productdetails: action.productDetails
            }


        default:
            return state;
    }

}


export default reducer