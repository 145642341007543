import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    centerContainer: {
        margin: 'auto',
        /* width: 65%; */
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%'
    },
    titelContainer:{
        padding:'2px 0px 0px 10px',
        fontWeight: 'bold',
        // color: '#00B8AC',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',   
            lineHeight: '1.66',
            letterSpacing: '0.03333em'
        }
    },

    footerDivStyle: {
        flexDirection: "row",
        display: "flex",
        alignItems: 'start',
        color: "#C0C0C0",
        [theme.breakpoints.down('sm')]: {
            // position: 'fixed',
            justifyContent: 'space-between',
            padding: '0px 10px 0px 0px',
            bottom: 0
        },
    },
    divStyle: {
        flexDirection: "column",
        display: "flex",
        textAlign: 'center',
        margin: '0 5px',
        padding: '0 5px'
    },
      // temp
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    muiIconButton:{
        padding: 5
    },

    controlSkeleton: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }


}));

export default useStyles;