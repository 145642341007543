import {
    successColor,
    roseColor
} from "assets/jss/material-dashboard-react.js";

const Style = theme => ({
    root: {

    },
    disabledListItem: {
        opacity: '1 !important'
    },
    ansListItemStyle: {
        paddingLeft: 0
    },

    ansGridContainer: {
        // justifyContent: "center"
    },
    ansButton: {
        maxWidth: "700px",
        marginBottom: 20
    },
    footerDiv: {
        display: "flex",
        justifyContent: 'space-between',
        marginTop: '10px'
    },

    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        // textAlign: 'center',
    },

    cardStyle: {
        marginBottom: "0",
        marginTop: '-150px',
        borderRadius: 20
        // padding: 20
        // backgroundColor:successColor[0]
    },

    cardIconStyle: {
        width: '90px',
        height: '90px',
        marginTop: '20px',
        marginBottom: '40px',
    },

    //-------Grid Container----
    gridContainer: {
        backgroundColor: successColor[0],
        margin: "0px",
        flex: '0 0 auto',
        [theme.breakpoints.down('sm')]: {
            // height: '100%',
            display: 'none'
        },
    },


    gridContainer1: {
        margin: "0px",
        marginTop: '-100px',
        flex: '0 0 auto',
        [theme.breakpoints.down('sm')]: {
            margin: "0px -15px",
        },
    },
    gridContainer2: {
        margin: "0px",
        minHeight: '200px',
        maxHeight: '80vh',
        flex: '1 1 auto',
        height: ' -webkit-fill-available',
        // padding: 8px 24px;
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            // height: '100%',
            margin: "0px -15px",
        },
        [theme.breakpoints.up('sm')]: {
            // height: '100%',
            // maxHeight: '80vh',
        },
    },
    gridContainer3: {
        minHeight: '200px',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
    },
    gridContainer4: {
        margin: "0px",
        flex: '0 0 auto',
        [theme.breakpoints.down('sm')]: {
            // height: '100%',
            margin: "0px -15px",
        },
    },


    //------CardStyle1-----
    cardStyle1: {
        marginBottom: "0",
        marginTop: '0px',
        // padding: 20,
        borderRadius: 0,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: 5,
        },
        // backgroundColor:successColor[0]
    },
    cardStyle2: {
        height: '100%',
        marginBottom: "0",
        overflowY: 'auto',
        overflowX: 'auto',
        // padding: 20,
        marginTop: 0,
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: 5,
        },
        // backgroundColor:successColor[0]
    },
    cardStyle3: {
        marginBottom: "0",
        padding: 5,
        marginTop: 0,
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: 5,
        },
        [theme.breakpoints.up('sm')]: {
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
        },
        // backgroundColor:successColor[0]
    },

    centerContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    gridItem: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%',
    },
    cardBodyStyle: {
        padding: 0
    },


    imageStyle: {
        width: "auto",
        height: "-webkit-fill-available"
    },

    earnPoints: {
        // padding: '5px 20px 5px 20px',
        width: 'fit-content',
        textAlign: 'center',
        Display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: "15px"
    },
    buttonCenter: {
        display: 'flex',
        justifyContent: "center"
    },
    // Customized radios
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    //checkbox
    formControl: {
        margin: theme.spacing(3),
    },

    checkboxLabelStyle: {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em',
        color: ' rgba(0, 0, 0, 0.87)'
    },

    // temp
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: roseColor[0],
    },

    //Button style
    buttonStyle: {
        padding: "8px 28px",
    },
    buttonBackNext: {
        padding: "8px 28px",
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px'
        },
    },
    divButtonsStyle: {
        display: 'flex',
        justifyContent: "space-between"
    },
    cardHeader: {
        padding: '0.25rem 1rem'
    },
    stepperCardBody: {
        padding: '0.25rem 1rem'
    },
    questioAnswersCardBody: {
        padding: '0 1rem 0.25rem 1rem',
    },
    stepperStyle: {
        padding: 10,
        marginLeft: "auto",
        marginRight: 'auto',
        overflowX: "overlay",
        overflowY: "hidden"
    },
    yourAnswerText: {
        fontWeight: 'bold',
        backgroundColor: "aliceblue",
        padding: '5px 0px 0px 10px',
        marginBottom: '-5px',//'-18px',
        zIndex: '100',
        position: 'relative'
    },
    correctAnswerText: {
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: "aliceblue",
        // padding: '0px 15px 5px 0px',
        //marginTop: '-22px',
        position: 'relative',
        zIndex: '100',
    },
    explanationHeaderText: {
        fontWeight: 'bold',
    },
    listItemContainer: {
        minWidth: 'fit-content'
    },
    secondaryAction: {
        transform: 'none',
        top: 'auto',
        bottom: 0
    }

})

export default Style;

